import { faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons"
import { faDiscord, faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonButton, IonContent, IonItem, IonLabel, IonList, IonMenu, IonRouterLink } from "@ionic/react"
import { useRef } from "react"
import { User } from "../../auth/model"
import postLogout from "../../api/v0/auth/logout/post"
import { SignInWithGoogleButton } from "../../auth/SignInWithGoogle"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

interface MenuProps {
	user?: User
	setUser: (user?: User) => void
}

const Menu: React.FC<MenuProps> = props => {
	const menuRef = useRef<HTMLIonMenuElement | null>(null)
	const isLoggedIn = props.user && props.user.id != "1"

	return (
		<IonMenu contentId="main" ref={menuRef}>
			<IonContent className="ion-padding light-bg">
				<IonList>
					<IonItem routerLink="/" onClick={handleNavigate}>
						<FontAwesomeIcon
							icon={faWandMagicSparkles}
							fontSize={"16px"}
							style={{ marginRight: "10px", marginTop: "-4px" }}
						/>
						<IonLabel>New Adventure</IonLabel>
					</IonItem>
					{props.user && (
						<IonItem routerLink={"/adventures"} onClick={handleNavigate}>
							<IonLabel>Your Adventures</IonLabel>
						</IonItem>
					)}
					<IonItem routerLink={"/community"} onClick={handleNavigate}>
						<IonLabel>Browse &amp; Discover</IonLabel>
					</IonItem>
					<IonItem routerLink={"/about"} onClick={handleNavigate}>
						<IonLabel>About Us</IonLabel>
					</IonItem>
					{isLoggedIn ? (
						<IonItem button={true} type="button" onClick={() => signOut()}>
							<IonLabel>Log out</IonLabel>
						</IonItem>
					) : (
						<div>
							<SignInWithGoogleButton user={props.user} completeSignIn={props.setUser} />
						</div>
					)}
				</IonList>

				<div className="contact-and-socials">
					<div className="socials">
						<a target="_blank" href="https://discord.gg/e9rUtHNNSG">
							<FontAwesomeIcon icon={faDiscord as IconProp} />
							<span className="sr-only">Discord</span>
						</a>
						<a target="_blank" href="https://www.instagram.com/cascadetravel.app">
							<FontAwesomeIcon icon={faInstagram as IconProp} />
							<span className="sr-only">Instagram</span>
						</a>
						<a target="_blank" href="https://www.facebook.com/cascadetravelapp">
							<FontAwesomeIcon icon={faFacebook as IconProp} />
							<span className="sr-only">Facebook</span>
						</a>
					</div>
					<br />
					<p>
						Have notes?{" "}
						<a target="_blank" href="https://forms.gle/VddPF3RYXJBRyrUw9">
							Take our customer feedback survey{" "}
						</a>
						to help us shape the initial release of Cascade
					</p>
					<p>
						Curious? Discover answers to{" "}
						<a
							target="_blank"
							href="https://storage.googleapis.com/cascade_travel_assets/Cascade%20FAQ.pdf"
						>
							frequently asked questions
						</a>
					</p>
					<p>
						Still have questions? Email us at{" "}
						<a target="_blank" href="mailto:support@cascadetravel.app">
							support@cascadetravel.app
						</a>
					</p>
				</div>
			</IonContent>
		</IonMenu>
	)

	async function handleNavigate(): Promise<void> {
		await menuRef?.current?.close()
	}

	function signOut(): void {
		postLogout() // .then(() => window.location.reload())
		// Wait for the menu to close
		setTimeout(() => {
			props.setUser(undefined)
		}, 1000)
		menuRef.current?.close()
	}
}

export default Menu
