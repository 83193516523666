import { get } from "../../../../api-utils"
import { ApiExperience } from "../../../api-model"
import { apiExperienceToExperience } from "../../../mapping"
import { Experience } from "../../../ui-model"
import { ErrorResponse } from "../versions/get"

export default async function (tripId: string): Promise<Experience[]> {
	const experiences = await get<ApiExperience[]>(`v0/trips/${tripId}/favorites`)
	// TODO handle payment required response
	/* message format
	{
		"message": "Payment required to create trip bb6db3b5",
		"access_code": "bb6db3b5"
	}
	 */
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const err = experiences as ErrorResponse
	if (err?.message) throw new Error(err?.message)
	if (!experiences) throw new Error(`GET /trips/${tripId}/favorites: response body was ${experiences}`)
	return experiences.map(apiExperienceToExperience)
}
