import logError from "../error-handling/log-error"

export const get = fetchFactory("GET")
export const post = fetchFactory("POST")
export const put = fetchFactory("PUT")
export const patch = fetchFactory("PATCH")

export const headers = {
	"Accept": "application/json",
	"Content-Type": "application/json",
}

function fetchFactory(method: "GET" | "POST" | "PUT" | "PATCH") {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	if (method === "POST" || method === "PUT" || method === "PATCH") {
		return async function <RespDataType>(
			path: string,
			body?: unknown,
			requestInit?: RequestInit,
		): Promise<RespDataType | undefined> {
			console.log(body)
			const response = await fetch(new URL(`${getApiUrl()}/${path}`), {
				method,
				body: JSON.stringify(body),
				headers: headers,
				credentials: "include",
				...requestInit,
			})
				.then(res => {
					if (!res.ok) {
						return res.json().then(resJson => {
							throw resJson
						})
					}
					return res.json()
				})
				.catch(reason => {
					logError(reason)
					throw reason
				})

			return response
		}
	}

	return async function <RespDataType>(
		path: string,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		queryParams?: Record<string, any>,
		requestInit?: RequestInit,
	): Promise<RespDataType | undefined> {
		const response = await fetch(new URL(`${getApiUrl()}/${path}?` + new URLSearchParams(queryParams ?? {})), {
			method: "GET",
			headers: headers,
			credentials: "include",
			...requestInit,
		})
			.then(res => {
				if (!res.ok) {
					return res.json().then(resJson => {
						throw resJson
					})
				}
				return res.json()
			})
			.catch(reason => {
				logError(reason)
				throw reason
			})

		return response
	}
}

function getApiUrl(): string {
	const CASCADE_API_URL = process.env.REACT_APP_CASCADE_API_URL
	if (!CASCADE_API_URL) {
		throw new Error("REACT_APP_CASCADE_API_URL is missing from the environment")
	}
	return CASCADE_API_URL
}
