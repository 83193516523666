import { useEffect, useState } from "react"
import { User } from "../../../auth/model"
import apiGetTrip from "../../../api/v0/trips/_code_/get"
import { Trip } from "../../../api/v0/ui-model"

export default (tripId: string, user: User | undefined): [Trip | undefined, NotFound, Loading, Error] => {
	const [trip, setTrip] = useState<Trip>()
	const [notFound, setNotFound] = useState<NotFound>(false)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState<Error>(undefined)

	useEffect(() => {
		if (!user) {
			return
		}

		apiGetTrip(tripId)
			.then(_trip => {
				if (!_trip) setNotFound(true)
				setTrip(_trip)
			})
			.catch(e => {
				console.error("error caught in use-trip:", e)
				setError(e)
			})
			.finally(() => {
				setLoading(false)
			})
	}, [tripId, user])

	return [trip, notFound, loading, error]
}

type Loading = boolean
type Error = unknown
type NotFound = boolean
