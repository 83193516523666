import { get } from "../../../../api-utils"
import { ApiItinerary } from "../../../api-model"
import { apiItineraryToTripSchedule } from "../../../mapping"
import { TripSchedule } from "../../../ui-model"

export default async function (tripId: string): Promise<TripSchedule[]> {
	const tripVersions = await get<ApiItinerary[]>(`v0/trips/${tripId}/versions`)
	// TODO handle payment required response
	/* message format
	{
		"message": "Payment required to create trip bb6db3b5",
		"access_code": "bb6db3b5"
	}
	 */
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const err = tripVersions as ErrorResponse
	if (err?.message) throw new Error(err?.message)
	if (!tripVersions) throw new Error(`GET /trips/${tripId}/versions: response body was ${tripVersions}`)
	return tripVersions.map(apiItineraryToTripSchedule)
}

export interface ErrorResponse {
	message: string
	accessCode: string
}
