import { get } from "../../../../../api-utils"
import { ApiExperienceDetail } from "../../../../api-model"
import { apiExperienceDetailToExperienceDetail, apiPhotoToPhoto } from "../../../../mapping"
import { ExperienceDetail } from "../../../../ui-model"

export default async function (tripId: string, experienceId: string): Promise<ExperienceDetail> {
	// return {
	// 	experienceId: "1",
	// 	name: "This Awesome Experience",
	// 	description: "Description of awesome experience. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
	// 	address: "123 Main St",
	// 	rating0To5: 4.5,
	// 	cost: "$$$",
	// 	totalNumberOfRatings: 10,
	// 	reviews: [
	// 		{
	// 			authorName: "Danny",
	// 			authorUrl: "https://google.com",
	// 			profilePhotoUrl: "",
	// 			rating0To5: 5,
	// 			howLongAgoPosted: "5 days",
	// 			postedAt: "",
	// 			text: "What a great experience! A plus! Would recommend!",
	// 		},
	// 		{
	// 			authorName: "Esha",
	// 			authorUrl: "https://google.com",
	// 			profilePhotoUrl: "",
	// 			rating0To5: 4,
	// 			howLongAgoPosted: "6 days",
	// 			postedAt: "",
	// 			text: "Solid experience. I would recommend it to a friend.",
	// 		}
	// 	],
	// 	website: "https://google.com",
	// 	phoneNumber: "123-456-7890",
	// 	hoursDescriptionByWeekday: [
	// 		"Open Sunday 9am-9pm",
	// 		"Open Monday 9am-9pm",
	// 		"Open Tuesday 9am-9pm",
	// 		"Open Wednesday 9am-9pm",
	// 		"Open Thursday 9am-9pm",
	// 		"Open Friday 9am-9pm",
	// 		"Open Saturday 9am-9pm",
	// 	],
	// 	photos: [
	// 		{
	// 			"width": 2305,
	// 			"height": 1536,
	// 			"src": "https://images.pexels.com/photos/2724664/pexels-photo-2724664.jpeg",
	// 			// "src": "",
	// 			"alt_text": "",
	// 			"provider": "",
	// 		}
	// 	]?.map(apiPhotoToPhoto),
	// 	types: [],
	// 	wheelchairAccessibleEntrance: true,
	// 	myPreference: "favorite",
	// }
	const expDetail = await get<ApiExperienceDetail>(`v0/trips/${tripId}/experience/${experienceId}`)
	if (!expDetail) throw new Error(`GET /trips/${tripId}/experience/${experienceId}: response body was ${expDetail}`)
	return apiExperienceDetailToExperienceDetail(expDetail)
}
