/* eslint-disable @typescript-eslint/no-namespace */
import { IonButton, IonIcon } from "@ionic/react"
import { StyleReactProps } from "@ionic/react/dist/types/components/react-component-lib/interfaces"
import { ReactElement, ReactNode } from "react"
import Swiper from "swiper"
import { register, SwiperContainer, SwiperSlide } from "swiper/element/bundle"
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons"

declare global {
	namespace JSX {
		interface IntrinsicElements {
			"swiper-container": React.DetailedHTMLProps<React.HTMLAttributes<SwiperContainer>, SwiperContainer>
			"swiper-slide": React.DetailedHTMLProps<React.HTMLAttributes<SwiperSlide>, SwiperSlide>
		}
	}
}

export interface SlidesProps {
	children: ReactElement[] | ReactNode
}

const Slides: React.FC<SlidesProps> = ({ children }) => (
	<div className="slides">
		<swiper-container>
			{(children as ReactElement[])?.map(child => (
				<swiper-slide key={child.key}>{child}</swiper-slide>
			))}
		</swiper-container>
		{((children as ReactElement[])?.length ?? 0) > 1 && (
			<div className="slides-buttons">
				<IonButton size="small" onClick={() => getSwiperInstance().slidePrev()} fill="clear">
					<IonIcon slot="icon-only" icon={chevronBackOutline} />
				</IonButton>
				<IonButton size="small" onClick={() => getSwiperInstance().slideNext()} fill="clear">
					<IonIcon slot="icon-only" icon={chevronForwardOutline} />
				</IonButton>
			</div>
		)}
	</div>
)

export function getSwiperInstance(): Swiper {
	return (document.querySelector("swiper-container") as SwiperContainer).swiper
}

export default Slides

register()
