import getUser from "../../api/v0/auth/get"
import { useEffect, useState } from "react"
import { User } from "../model"

/** https://help.fullstory.com/hc/en-us/articles/360020828113-FS-identify-Identifying-users */
interface FullStory {
	identify: (uid: string) => void
	/** https://help.fullstory.com/hc/en-us/articles/360020623294-FS-setUserVars-API-Capturing-custom-user-properties */
	setUserVars: (userVars: Record<string, unknown>) => void
}
declare const FS: FullStory

export const useUser = (): [User | undefined, SetUser, Loading, Error] => {
	const [appUser, setUser] = useState<User>()
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState<Error>(undefined)

	useEffect(() => {
		getUser()
			.then(user => {
				setUser(user)
				FS.identify(user.id)
				FS.setUserVars({ ...user })
			})
			.catch(e => {
				setError(e)
			})
			.finally(() => {
				setLoading(false)
			})
	}, [])

	return [appUser, setUser, loading, error]
}

type SetUser = (user?: User) => void

type Loading = boolean

type Error = unknown
