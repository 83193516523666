import { capitalize } from "lodash"
import {
	ApiExperience,
	ApiExperienceDetail,
	ApiExperiencePreference,
	ApiIdea,
	ApiItinerary,
	ApiItineraryDay,
	ApiItineraryItem,
	ApiTripFeedback,
	ApiTripPlan,
	ApiTripRequest,
	ApiPhoto,
} from "./api-model"
import {
	Experience,
	ExperienceDetail,
	Idea,
	Photo,
	ScheduledExperience,
	Trip,
	TripDay,
	TripFeedback,
	TripInput,
	TripSchedule,
} from "./ui-model"
import { format, parseISO } from "date-fns"

export function tripInputToApiTripRequest(tripInput: TripInput): ApiTripRequest {
	return {
		destination: tripInput.destination,
		travellers: tripInput.numberOfTravelers,
		duration: tripInput.durationDays,
		// TODO: Figure out how arrival_time (time only) should be interpreted by the UI
		// and whether the UI should be parsing it or just passing the datetime
		arrival_time: tripInput.arrivalTime,
		departure_time: tripInput.departureTime,
		start_date: tripInput.startDate,
		interests: tripInput.interests.split(/[;,]/g).map(x => x.trim()),
		// transport: tripInput.transport,
		// accommodation: tripInput.accommodation,
		// budget: tripInput.budget,
	}
}

export function tripFeedbackToApiTripFeedback(tripFeedback: TripFeedback): ApiTripFeedback {
	return {
		feedback: tripFeedback.feedback,
	}
}

export function apiIdeaToIdea(apiIdea: ApiIdea): Idea {
	return {
		id: apiIdea.idea_id.toString(),
		title: apiIdea.title,
		description: apiIdea.description,
		photo: { src: apiIdea.photo, altText: "poster image for " + apiIdea.title },
		budget: apiIdea.budget,
		destination: apiIdea.destination,
		numberOfTravelers: apiIdea.travellers,
		durationDays: apiIdea.duration,
		// TODO: Figure out how arrival_time (time only) should be interpreted by the UI
		// and whether the UI should be parsing it or just passing the datetime
		arrivalTime: apiIdea.arrival_time,
		departureTime: apiIdea.departure_time,
		interests: apiIdea.interests.join("; "),
		order: apiIdea.order,
	}
}

export function apiExperienceToExperience(apiExp: ApiExperience): Experience {
	return {
		id: apiExp.experience_id?.toString() ?? "",
		thirdPartyId: apiExp.third_party_id,
		name: apiExp.name,
		description: apiExp.description ?? "",
		photos: apiExp.photos?.map(apiPhotoToPhoto) ?? [],
		myPreference: experiencePreferenceFromApi(apiExp.preference?.type),
		address: apiExp.address,
		rating0To5: apiExp.rating,
		geolocation: {
			location: {
				lat: parseFloat(apiExp.geolocation!.lat),
				lng: parseFloat(apiExp.geolocation!.lng),
			},
		},
	}
}

export function apiExperienceDetailToExperienceDetail(apiExpDetail: ApiExperienceDetail): ExperienceDetail {
	return {
		experienceId: apiExpDetail.detail.experience_id.toString(),
		name: apiExpDetail.name,
		description: apiExpDetail.detail.description,
		address: apiExpDetail.address,
		rating0To5: apiExpDetail.detail.rating,
		cost: priceLevelToCost(apiExpDetail.detail.price_level),
		totalNumberOfRatings: apiExpDetail.detail.user_ratings_total,
		reviews:
			apiExpDetail.detail.reviews?.map(review => ({
				authorName: review.author_name,
				authorUrl: review.author_url,
				profilePhotoUrl: review.profile_photo_url,
				rating0To5: review.rating,
				howLongAgoPosted: review.relative_time_description,
				postedAt: new Date(review.time).toISOString(),
				text: review.text,
			})) ?? [],
		website: apiExpDetail.detail.website,
		phoneNumber: apiExpDetail.detail.phone_number,
		hoursDescriptionByWeekday: apiExpDetail.detail.hours?.weekday_text ?? [],
		photos: apiExpDetail.detail.photos?.map(apiPhotoToPhoto),
		types: apiExpDetail.detail.types,
		wheelchairAccessibleEntrance: apiExpDetail.detail.wheelchair_accessible_entrance,
		myPreference: experiencePreferenceFromApi(apiExpDetail.preference?.type),
	}
}

function priceLevelToCost(price0To5: number): string {
	let costStr = ""
	for (let i = 1; i <= price0To5; i++) {
		costStr += "$"
	}
	return costStr
}

export function apiPhotoToPhoto(photo: ApiPhoto): Photo {
	return {
		widthPx: photo.width ?? null,
		heightPx: photo.height ?? null,
		src: photo.src,
		altText: photo.alt_text,
	}
}

export function apiTripPlanToTrip(apiTripPlan: ApiTripPlan): Trip {
	return {
		id: apiTripPlan.access_code,
		name: apiTripPlan.name,
		owner: {
			firstName: "CHANGE ME",
		},
		schedule: apiItineraryToTripSchedule(apiTripPlan.itinerary),
	}
}

export function apiItineraryToTripSchedule(apiItin: ApiItinerary): TripSchedule {
	return {
		id: apiItin.itinerary_id?.toString() ?? "",
		ownerUserId: `${apiItin.user}`,
		userRating: apiItin.user_rating?.rating ?? 0,
		name: apiItin.itinerary_name,
		status: apiItin.status as TripSchedule["status"],
		days: apiItin.itinerary.map(apiItineraryDayToTripDay),
		coverImage: apiItin.cover_photo ? apiPhotoToPhoto(apiItin.cover_photo) : undefined,
		version: apiItin.version,
	}
}

function apiItineraryDayToTripDay(apiItinDay: ApiItineraryDay): TripDay {
	const lodging = apiItinDay.lodging ? apiExperienceToExperience(apiItinDay.lodging) : undefined
	return {
		dayNumber: apiItinDay.day_number,
		date: apiItinDay.date ? format(parseISO(apiItinDay.date), "MMM d, y") : "",
		events: apiItinDay.schedule.map(apiItineraryItemToScheduledExperience),
		summary: apiItinDay.summary,
		lodgingCity: apiItinDay.lodging_city,
		lodging: lodging,
	}
}

function apiItineraryItemToScheduledExperience(apiItinItem: ApiItineraryItem): ScheduledExperience {
	return {
		type: "experience",
		id: (apiItinItem.experience!.experience_id ?? "").toString(),
		thirdPartyId: apiItinItem.experience!.third_party_id ?? "",
		name: apiItinItem.activity,
		description: apiItinItem.experience?.description ?? "",
		photos: apiItinItem.experience?.photos?.map(apiPhotoToPhoto) ?? [],
		myPreference: experiencePreferenceFromApi(apiItinItem.experience!.preference?.type),
		address: apiItinItem.experience!.address,
		rating0To5: apiItinItem.experience!.rating,
		cost: apiItinItem.experience?.price_level
			? priceLevelToCost(apiItinItem.experience.price_level)
			: apiItinItem.cost,
		startTime: apiItinItem.time,
		duration: apiItinItem.duration,
		geolocation: {
			location: {
				lat: parseFloat(apiItinItem.experience!.geolocation!.lat),
				lng: parseFloat(apiItinItem.experience!.geolocation!.lng),
			},
		},
	}
}

export function experiencePreferenceToApi(pref: Experience["myPreference"]): ApiExperiencePreference["type"] {
	return capitalize(pref) as ApiExperiencePreference["type"]
}

export function experiencePreferenceFromApi(apiPref?: ApiExperiencePreference["type"]): Experience["myPreference"] {
	if (!apiPref) return "neutral"
	return apiPref.toLowerCase() as Experience["myPreference"]
}
