export const ADVENTURE_PAGE = "go/:tripId"
export const getAdventurePagePath = (tripId: string) => `go/${tripId}`

export const ADVENTURE_TAB = "go/:tripId/adventure"
export const getAdventureTabPath = (tripId: string) => `go/${tripId}/adventure`

export const DISCOVER_TAB = "go/:tripId/discover"
export const getDiscoverTabPath = (tripId: string) => `go/${tripId}/discover`

export const FAVORITES_TAB = "go/:tripId/favorites"
export const getFavoritesTabPath = (tripId: string) => `go/${tripId}/favorites`

export const EXPERIENCE_DETAIL = "go/:tripId/experience/:experienceId"
export const getExperienceDetailPath = (tripId: string, experienceId: string) =>
	`go/${tripId}/experience/${experienceId}`

export const PAY_WALL_PAGE = "pay-wall/:tripId"
export const getPayWallPagePath = (tripId: string) => `pay-wall/${tripId}`
