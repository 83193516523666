import { useEffect, useState } from "react"
import apiGetExpDetail from "../../../api/v0/trips/_code_/experience/_id_/get"
import { ExperienceDetail } from "../../../api/v0/ui-model"
import { User } from "../../../auth/model"

export default (
	tripId: string,
	experienceId: string,
	user: User | undefined,
): [ExperienceDetail | undefined, (eagerUpdate?: ExperienceDetail) => void, Loading, Error] => {
	const [expDetail, setExpDetail] = useState<ExperienceDetail | undefined>()
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState<Error>(undefined)

	const load = (eagerUpdate?: ExperienceDetail) => {
		if (eagerUpdate) {
			setExpDetail(eagerUpdate)
		} else {
			getExpDetail(tripId, experienceId, user)
				.then(setExpDetail)
				.catch(e => {
					console.error("error caught in use-favorites:", e)
					setError(e)
				})
				.finally(() => setLoading(false))
		}
		return () => {
			setExpDetail(undefined)
			setError(undefined)
		}
	}

	useEffect(load, [tripId, experienceId, user])

	return [expDetail, load, loading, error]
}

const getExpDetail = async function (tripId: string, expId: string, user: User | undefined) {
	return await apiGetExpDetail(tripId, expId)
}

type Loading = boolean
type Error = unknown
