import { FC, useState } from "react"
import { CredentialResponse, GoogleLogin } from "@react-oauth/google"
import postLogin from "../api/v0/auth/post"
import getUser from "../api/v0/auth/get"
import { User } from "./model"
import logError from "../error-handling/log-error"
import { IonContent, IonSpinner } from "@ionic/react"

interface SignInWithGoogleProps {
	user?: User
	completeSignIn: (user: User) => void
}

const SignInWithGoogleModal: FC<SignInWithGoogleProps> = props => {
	const [googleAuthSucceeded, setGoogleAuthSucceeded] = useState(false)

	const responseGoogle = async (response: CredentialResponse) => {
		if (response.credential) {
			setGoogleAuthSucceeded(true)
			await postLogin(response.credential)
			props.completeSignIn(await getUser())
		} else {
			// todo handle auth failure
			logError("failed to get user credentials")
		}
	}

	const responseGoogleFailure = async () => {
		// todo handle auth failure
		logError("sign in encountered an error")
	}

	return (
		<IonContent id="sign-in-popup-content" className="light-bg">
			<div id="sign-in-popup-content-inner">
				<h2 className="large-title">Sign in to continue</h2>
				{googleAuthSucceeded ? (
					<>
						<IonSpinner color="medium" />
					</>
				) : (
					<>
						<script src="https://accounts.google.com/gsi/client" async defer></script>
						<div id="sign-in-button">
							<GoogleLogin
								// clientId={props.loginUrl}
								// buttonText="Custom Sign in with Google"
								onSuccess={responseGoogle}
								onError={responseGoogleFailure}
								// type="icon"
								shape="pill"
								theme="outline"
								size="large"
								// useOneTap
							/>
						</div>
					</>
				)}
			</div>
		</IonContent>
	)
}

export default SignInWithGoogleModal

export const SignInWithGoogleButton: FC<SignInWithGoogleProps> = props => {
	const [googleAuthSucceeded, setGoogleAuthSucceeded] = useState(false)

	const responseGoogle = async (response: CredentialResponse) => {
		if (response.credential) {
			setGoogleAuthSucceeded(true)
			await postLogin(response.credential)
			props.completeSignIn(await getUser())
		} else {
			// todo handle auth failure
			logError("failed to get user credentials")
		}
	}

	const responseGoogleFailure = async () => {
		// todo handle auth failure
		logError("sign in encountered an error")
	}

	return (
		<>
			<script src="https://accounts.google.com/gsi/client" async defer></script>
			<div id="sign-in-button">
				{googleAuthSucceeded ? (
					<IonSpinner color="medium" />
				) : (
					<GoogleLogin
						// clientId={props.loginUrl}
						// buttonText="Custom Sign in with Google"
						onSuccess={responseGoogle}
						onError={responseGoogleFailure}
						// type="icon"
						shape="pill"
						theme="outline"
						size="large"
						// useOneTap
					/>
				)}
			</div>
		</>
	)
}
