import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonCol,
	IonContent,
	IonGrid,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonLoading,
	IonPage,
	IonRouterLink,
	IonRow,
} from "@ionic/react"
import { useState } from "react"
import { ExperiencePhoto } from "../../components/experience/ExperienceCard"
import Masthead from "../../components/header/Masthead"
import { PageProps } from "../model"
import { getAdventureTabPath } from "../routing/routes"
import useSavedTrips from "./hooks/use-user-trips"

export interface SavedAdventuresPageProps extends PageProps {}

const SavedAdventuresPage: React.FC<SavedAdventuresPageProps> = ({ user, userLoading }) => {
	const [trips, currentPageTrips, loadTrips, loading, error] = useSavedTrips(user)
	const [page, setPage] = useState(1)
	const [cachedExpCardCoverPhotoHeight, setCachedExpCardCoverPhotoHeight] = useState<string>()

	if (error) throw error

	if (loading)
		return (
			<IonPage>
				<IonLoading isOpen={true} />
			</IonPage>
		)

	return (
		<IonPage className="light-bg">
			<Masthead user={user} userLoading={userLoading} background="frosted"></Masthead>

			<IonContent fullscreen>
				<IonGrid
					role="feed"
					className="page-max-width extend-behind-masthead"
					style={{ paddingTop: "var(--cscd-masthead-height)" }}
				>
					<IonRow>
						<IonCol>
							<h1 className="larger-title ion-padding">Your Adventures</h1>
						</IonCol>
					</IonRow>

					<IonRow>
						{trips.map(trip => (
							<IonCol size="12" sizeLg="6" sizeXl="4" key={trip.id}>
								<IonCard role="article" className="content-card">
									<IonCardContent className="visual-card-content-top">
										<IonRouterLink routerLink={`/${getAdventureTabPath(trip.id)}`}>
											{trip.schedule.coverImage && (
												<ExperiencePhoto
													{...trip.schedule.coverImage}
													aspectRatio={2}
													cachedExpCardCoverPhotoHeight={cachedExpCardCoverPhotoHeight}
													setCachedExpCardCoverPhotoHeight={setCachedExpCardCoverPhotoHeight}
												/>
											)}
										</IonRouterLink>
										<div className="content-card-actions"></div>
									</IonCardContent>

									<IonCardHeader>
										<div className="content-card-header">
											<div className="experience-card-header-main">
												<IonCardTitle className="medium-title">
													<IonRouterLink routerLink={`/${getAdventureTabPath(trip.id)}`}>
														{trip.name}
													</IonRouterLink>
												</IonCardTitle>
												{/* <IonCardSubtitle className="normal-text">
												{experience.description}
											</IonCardSubtitle> */}
											</div>
											{/* <p className="experience-card-place-info">
											<>{experience.cost != undefined ? <>{experience.cost}&nbsp;&nbsp;&nbsp;</> : ""}</>
											{experience.rating0To5 != undefined && <strong>★&nbsp;{experience.rating0To5}</strong>}
										</p> */}
										</div>
									</IonCardHeader>
								</IonCard>
							</IonCol>
						))}
					</IonRow>
				</IonGrid>
				<IonInfiniteScroll
					onIonInfinite={ev => {
						if (currentPageTrips.length === 10) {
							setPage(page + 1)
							loadTrips(page + 1).then(() => {
								ev.target.complete()
							})
						} else {
							ev.target.disabled = true
						}
					}}
				>
					<IonInfiniteScrollContent loadingSpinner="dots" />
				</IonInfiniteScroll>
			</IonContent>
		</IonPage>
	)
}

export default SavedAdventuresPage
