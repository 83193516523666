export function findIndexOfClosestNumber(arr: (number | undefined)[], target: number): number {
	let bestMatch: number | undefined
	let bestMatchIndex = -1

	arr.forEach((num, idx) => {
		if (num === undefined) return
		if (
			bestMatch === undefined ||
			num === target ||
			(target > bestMatch && num > bestMatch && num < target) ||
			(target < bestMatch && num < bestMatch && num > target)
		) {
			bestMatch = num
			bestMatchIndex = idx
		}
	})

	return bestMatchIndex
}
