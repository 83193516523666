import { useEffect, useState } from "react"
import apiFindFavorites from "../../../api/v0/trips/_code_/favorites/get"
import { Experience } from "../../../api/v0/ui-model"
import { User } from "../../../auth/model"

let cache: Experience[] | undefined

export default (
	tripId: string,
	latestGoodScheduleId: string | undefined,
	user: User | undefined,
): [Experience[], (eagerUpdate?: Experience[]) => void, Loading, Error] => {
	const [experiences, setExperiences] = useState<Experience[]>([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState<Error>(undefined)

	const load = (eagerUpdate?: Experience[]) => {
		if (!user) {
			return
		}
		if (eagerUpdate) {
			setExperiences(eagerUpdate)
			cache = eagerUpdate
		} else {
			if (cache) {
				setExperiences(cache)
				setLoading(false)
			}
			findFavorites(tripId, user)
				.then(data => {
					setExperiences(data)
					cache = data
				})
				.catch(e => {
					console.error("error caught in use-favorites:", e)
					setError(e)
				})
				.finally(() => setLoading(false))
		}
	}

	useEffect(load, [tripId, user])

	return [experiences, load, loading, error]
}

const findFavorites = async function (tripId: string, user: User | undefined) {
	return await apiFindFavorites(tripId)
}

type Loading = boolean
type Error = unknown
