import { IonButton } from "@ionic/react"
import { FC, ReactNode } from "react"

export interface ViewToggleProps {
	onToggle: () => void
	children: ReactNode
}

const ViewToggle: FC<ViewToggleProps> = ({ children, onToggle }) => {
	return (
		<IonButton className="view-toggle label" fill="clear" slot="end" onClick={onToggle}>
			{children}
		</IonButton>
	)
}

export default ViewToggle
