import {
	IonLoading,
	IonModal,
	IonPage,
	IonProgressBar,
	IonRouterOutlet,
	useIonModal,
	useIonRouter,
	useIonToast,
} from "@ionic/react"
import { FC, memo, useEffect, useRef, useState } from "react"
import { Redirect, Route, useParams } from "react-router"
import NotFoundPage from "../../components/not-found/NotFound"
import { MOBILE_LAYOUT_MAX_WIDTH_PX } from "../../layout/config"
import useWindowSize from "../../layout/hooks/use-window-size"
import { PageProps } from "../model"
import { RouteParams } from "../routing/route-params"
import {
	ADVENTURE_PAGE,
	ADVENTURE_TAB,
	EXPERIENCE_DETAIL,
	getAdventureTabPath,
	getPayWallPagePath,
} from "../routing/routes"
import AdventureBackgroundImage from "./AdventureBackgroundImage"
import useCurrentSchedule from "./hooks/use-current-schedule"
import useFavorites from "./hooks/use-favorites"
import useTrip from "./hooks/use-trip"
import AdventureScheduleTab from "./tabs/AdventureScheduleTab"
import ExperienceDetailPage from "./tabs/ExperienceDetailPage"
import TabMasthead from "./TabMasthead"
import FeedbackForm, { FeedbackFormProps } from "./FeedbackForm"

export interface AdventurePageProps extends PageProps {
	signIn: () => Promise<void>
}

const AdventurePage: FC<AdventurePageProps> = ({ user, userLoading, signIn }) => {
	const { tripId } = useParams<RouteParams>()
	const [trip, tripNotFound, loadingTrip, tripErr] = useTrip(tripId, user)
	if (tripErr) console.error(tripErr)
	const [currentSchedule, refreshCurrSchedule, currSchdNotFound, loadingCurrSchd, regeneratingSchd, currSchdErr] =
		useCurrentSchedule(tripId, user)
	if (currSchdErr) console.error(currSchdErr)
	const [favorites, refreshFavorites, loadingFavorites] = useFavorites(tripId, currentSchedule?.id, user)
	// const [discover, refreshDiscover, loadingDiscover] = useDiscover(tripId, currentSchedule?.id, user)
	const windowSize = useWindowSize()
	const isPortraitLayout = (windowSize.width ?? 0) <= MOBILE_LAYOUT_MAX_WIDTH_PX
	const [showMapViewToggle, setShowMapViewToggle] = useState(false)
	const [mapOrListView, setMapOrListView] = useState<"map" | "list">("list")
	const [cachedExpCardCoverPhotoHeight, setCachedExpCardCoverPhotoHeight] = useState<string>()
	const [carouselIdxAdventureTab, setCarouselIdxAdventureTab] = useState<number>(0)
	const [scrollPosAdventureTab, setScrollPosAdventureTab] = useState<number>(0)
	// const [carouselIdxDiscoverTab, setCarouselIdxDiscoverTab] = useState<number>(0)
	// const [scrollPosDiscoverTab, setScrollPosDiscoverTab] = useState<number>(0)
	// const [carouselIdxFavoritesTab, setCarouselIdxFavoritesTab] = useState<number>(0)
	// const [scrollPosFavoritesTab, setScrollPosFavoritesTab] = useState<number>(0)
	const [forceShowDefaultPreLoginView, setForceShowDefaultPreLoginView] = useState(false)
	const router = useIonRouter()
	const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false)
	const [feedbackModalSkipRating, setFeedbackModalSkipRating] = useState(false)
	const [showToast, dismissToast] = useIonToast()
	const [feedbackModalBreakpoint, setFeedbackModalBreakpoint] = useState(300 / (windowSize.height ?? 768))
	const [feedbackInputFocused, setFeedbackInputFocused] = useState(false)
	const feedbackModalRef = useRef<HTMLIonModalElement>(null)
	// const [presentFeedbackModal, dismissFeedbackModal] = useIonModal(FeedbackForm, {
	// 	dismiss: (data: string, role: string) => dismissFeedbackModal(data, role),
	// 	trip,
	// 	currentSchedule,
	// 	refreshCurrentSchedule: refreshCurrSchedule,
	// 	skipRating: feedbackModalSkipRating,
	// 	onInputFocus: () => setFeedbackInputFocused(true),
	// 	onInputBlur: () => setFeedbackInputFocused(false),
	// } as FeedbackFormProps)
	const routerOutletRef = useRef<HTMLIonRouterOutletElement | null>(null)
	const [showLoadingBar, setShowLoadingBar] = useState(false)

	function toggleTripFeedbackForm(skipRating: boolean): void {
		setFeedbackModalSkipRating(skipRating)
		if (!isFeedbackModalOpen && feedbackModalRef) {
			feedbackModalRef.current?.present()
			// feedbackModalRef.current?.present({
			// 	initialBreakpoint: feedbackModalBreakpoint,
			// 	breakpoints: [0, feedbackModalBreakpoint],
			// 	backdropBreakpoint: 1,
			// 	showBackdrop: false,
			// 	backdropDismiss: !isPortraitLayout,
			// 	canDismiss: true,
			// 	handleBehavior: "cycle",
			// 	onDidDismiss: () => setIsFeedbackModalOpen(false),
			// })
			setIsFeedbackModalOpen(true)
		} else {
			feedbackModalRef.current?.dismiss?.()
			setIsFeedbackModalOpen(false)
		}
	}

	useEffect(() => {
		if (feedbackInputFocused) {
			setFeedbackModalBreakpoint(0.9)
		} else {
			setFeedbackModalBreakpoint(300 / (windowSize.height ?? 768))
		}
	}, [windowSize.width, feedbackInputFocused])

	useEffect(() => {
		feedbackModalRef.current?.setCurrentBreakpoint(feedbackModalBreakpoint)
	}, [feedbackModalBreakpoint])

	useEffect(() => {
		if (tripErr) {
			const paymentRequiredErr = tripErr as { message: string; access_code: string }
			if (paymentRequiredErr?.message?.match(/payment required/gi)) {
				setForceShowDefaultPreLoginView(true)
				setTimeout(() => {
					router.push(`/${getPayWallPagePath(paymentRequiredErr.access_code)}`)
				})
			}
		}
	}, [tripErr])

	useEffect(() => {
		if (!isPortraitLayout) {
			setShowMapViewToggle(false)
		}
	}, [windowSize.width])

	useEffect(() => {
		if (!userLoading && !user) {
			signIn()
			setTimeout(() => setForceShowDefaultPreLoginView(true), 250)
		} else {
			setForceShowDefaultPreLoginView(false)
		}
	}, [user, userLoading])

	useEffect(() => {
		if (currentSchedule?.status === "generating") {
			showToast("Your adventure is being planned...")
		} else {
			dismissToast()
		}
	}, [currentSchedule])

	useEffect(() => {
		if (regeneratingSchd) {
			showToast("Your adventure is being re-planned...")
			setShowLoadingBar(true)
		} else if (showLoadingBar) {
			// Dismiss the "regenerating" toast when the new adventure appears
			setShowLoadingBar(false)
			dismissToast().then(() => {
				setTimeout(() => showToast("Your new adventure is here!", 4000))
			})
		}
	}, [regeneratingSchd, showLoadingBar])

	if (forceShowDefaultPreLoginView) {
		return <IonPage style={{ background: "url(/assets/adventure-default-bg.jpg) 50% 50% / cover" }}></IonPage>
	}

	if (userLoading || loadingTrip || !trip || !currentSchedule) {
		return (
			<IonPage>
				<IonLoading isOpen={true} />
			</IonPage>
		)
	}

	if (tripNotFound || currSchdNotFound) {
		return <NotFoundPage user={user} userLoading={userLoading} />
	}

	return (
		<IonRouterOutlet animated={false} ref={routerOutletRef}>
			<Route exact path={`/${ADVENTURE_TAB}`}>
				<IonPage
					className={`adventure-page dark-bg ${isPortraitLayout ? "portrait-layout" : "landscape-layout"}`}
				>
					<AdventureBackgroundImage {...currentSchedule} />
					<TabMasthead
						user={user}
						userLoading={userLoading}
						trip={trip}
						currentSchedule={currentSchedule}
						loadingCurrentSchedule={loadingCurrSchd}
						refreshCurrentSchedule={refreshCurrSchedule}
						favorites={favorites}
						refreshFavorites={refreshFavorites}
						mapOrListView={mapOrListView}
						setMapOrListView={setMapOrListView}
						showMapViewToggle={showMapViewToggle}
						setShowMapViewToggle={setShowMapViewToggle}
						background={
							!isPortraitLayout || (mapOrListView === "list" && scrollPosAdventureTab <= 0)
								? "transparent"
								: "frosted"
						}
						isPortraitLayout={isPortraitLayout}
						toggleTripFeedbackForm={toggleTripFeedbackForm}
					/>
					<AdventureScheduleTab
						loading={false}
						user={user}
						userLoading={userLoading}
						trip={trip}
						mapOrListView={mapOrListView}
						currentSchedule={currentSchedule}
						refreshCurrentSchedule={refreshCurrSchedule}
						favorites={favorites}
						refreshFavorites={refreshFavorites}
						// discover={discover}
						// refreshDiscover={refreshDiscover}
						setShowMapViewToggle={setShowMapViewToggle}
						setMapOrListView={setMapOrListView}
						cachedExpCardCoverPhotoHeight={cachedExpCardCoverPhotoHeight}
						setCachedExpCardCoverPhotoHeight={setCachedExpCardCoverPhotoHeight}
						scrollPosPx={scrollPosAdventureTab}
						setScrollPosPx={setScrollPosAdventureTab}
						carouselIndex={carouselIdxAdventureTab}
						setCarouselIndex={setCarouselIdxAdventureTab}
						toggleTripFeedbackForm={toggleTripFeedbackForm}
					/>
					{showLoadingBar && <IonProgressBar type="indeterminate" />}

					<IonModal
						ref={feedbackModalRef}
						initialBreakpoint={feedbackModalBreakpoint}
						breakpoints={[0, feedbackModalBreakpoint]}
						backdropBreakpoint={1}
						showBackdrop={false}
						backdropDismiss={!isPortraitLayout}
						canDismiss={true}
						handleBehavior={"cycle"}
						onDidDismiss={() => setIsFeedbackModalOpen(false)}
					>
						<FeedbackForm
							dismiss={(data, role) => feedbackModalRef.current!.dismiss(data, role)}
							trip={trip}
							currentSchedule={currentSchedule}
							refreshCurrentSchedule={refreshCurrSchedule}
							skipRating={feedbackModalSkipRating}
							onInputFocus={() => setFeedbackInputFocused(true)}
							onInputBlur={() => setFeedbackInputFocused(false)}
						></FeedbackForm>
					</IonModal>
				</IonPage>
			</Route>
			<Route exact path={`/${EXPERIENCE_DETAIL}`}>
				<IonPage
					className={`adventure-page dark-bg ${isPortraitLayout ? "portrait-layout" : "landscape-layout"}`}
				>
					<AdventureBackgroundImage {...currentSchedule} />
					<ExperienceDetailPage
						user={user}
						userLoading={userLoading}
						trip={trip}
						currentSchedule={currentSchedule}
						refreshCurrentSchedule={refreshCurrSchedule}
						// discover={discover}
						// refreshDiscover={refreshDiscover}
						favorites={favorites}
						refreshFavorites={refreshFavorites}
						mapOrListView={mapOrListView}
						setShowMapViewToggle={setShowMapViewToggle}
						setMapOrListView={setMapOrListView}
						toggleTripFeedbackForm={toggleTripFeedbackForm}
					/>
					{showLoadingBar && <IonProgressBar type="indeterminate" />}
				</IonPage>
			</Route>
			<Route exact path={`/${ADVENTURE_PAGE}/`}>
				<Redirect to={`/${getAdventureTabPath(trip.id)}`} />
			</Route>
			<Route exact path={`/${ADVENTURE_PAGE}`}>
				<Redirect to={`/${getAdventureTabPath(trip.id)}`} />
			</Route>
			{/* <Route exact path={`/${DISCOVER_TAB}`}>
				<TabMasthead
					user={user}
					trip={trip}
					currentSchedule={currentSchedule}
					loadingCurrentSchedule={loadingCurrSchd}
					refreshCurrentSchedule={refreshCurrSchedule}
					mapOrListView={mapOrListView}
					setMapOrListView={setMapOrListView}
					showMapViewToggle={showMapViewToggle}
					background="frosted"
					// background={scrollPosDiscoverTab <= 0 ? "transparent" : "frosted"}
					isPortraitLayout={isPortraitLayout}
				/>
				<ExperienceListTab
					whichTab="discover"
					loading={loadingDiscover}
					user={user}
					trip={trip}
					mapOrListView={mapOrListView}
					currentSchedule={currentSchedule!}
					refreshCurrentSchedule={refreshCurrSchedule}
					favorites={favorites}
					refreshFavorites={refreshFavorites}
					discover={discover}
					refreshDiscover={refreshDiscover}
					setShowMapViewToggle={setShowMapViewToggle}
					setMapOrListView={setMapOrListView}
					cachedExpCardCoverPhotoHeight={cachedExpCardCoverPhotoHeight}
					setCachedExpCardCoverPhotoHeight={setCachedExpCardCoverPhotoHeight}
					scrollPosPx={scrollPosDiscoverTab}
					setScrollPosPx={setScrollPosDiscoverTab}
				/>
			</Route>
			<Route exact path={`/${FAVORITES_TAB}`}>
				<TabMasthead
					user={user}
					trip={trip}
					currentSchedule={currentSchedule}
					loadingCurrentSchedule={loadingCurrSchd}
					refreshCurrentSchedule={refreshCurrSchedule}
					mapOrListView={mapOrListView}
					setMapOrListView={setMapOrListView}
					showMapViewToggle={showMapViewToggle}
					background="frosted"
					// background={scrollPosDiscoverTab <= 0 ? "transparent" : "frosted"}
					isPortraitLayout={isPortraitLayout}
				/>
				<ExperienceListTab
					whichTab="favorites"
					loading={false}
					user={user}
					trip={trip}
					mapOrListView={mapOrListView}
					currentSchedule={currentSchedule!}
					refreshCurrentSchedule={refreshCurrSchedule}
					favorites={favorites}
					refreshFavorites={refreshFavorites}
					discover={discover}
					refreshDiscover={refreshDiscover}
					setShowMapViewToggle={setShowMapViewToggle}
					setMapOrListView={setMapOrListView}
					cachedExpCardCoverPhotoHeight={cachedExpCardCoverPhotoHeight}
					setCachedExpCardCoverPhotoHeight={setCachedExpCardCoverPhotoHeight}
					scrollPosPx={scrollPosFavoritesTab}
					setScrollPosPx={setScrollPosFavoritesTab}
				/>
			</Route> */}
		</IonRouterOutlet>
		/* <IonTabs>
			<IonTabBar slot="bottom">
				<IonTabButton tab="adventure" href={`/${getAdventureTabPath(tripId)}`}>
					{loadingCurrSchd
						? <IonSpinner name="dots" />
						: <FontAwesomeIcon icon={faWandMagicSparkles} fontSize={"15px"} />}
					<IonLabel className="small-label">Adventure</IonLabel>
				</IonTabButton>

				<IonTabButton tab="discover" href={`/${getDiscoverTabPath(tripId)}`}>
					<FontAwesomeIcon icon={faCompass} fontSize={"17px"} />
					<IonLabel className="small-label">Discover</IonLabel>
				</IonTabButton>

				<IonTabButton tab="favorites" href={`/${getFavoritesTabPath(tripId)}`}>
					<FontAwesomeIcon icon={faHeart} fontSize={"18px"} />
					<IonLabel className="small-label">Favorites</IonLabel>
				</IonTabButton>
			</IonTabBar>
		</IonTabs> */
	)
}

export default memo(AdventurePage)
