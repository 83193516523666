import { faHeart as faHeartReg } from "@fortawesome/free-regular-svg-icons"
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons"
import { Subject } from "rxjs"
import { throttleTime } from "rxjs/operators"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonRouterLink } from "@ionic/react"
import { FC, ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react"
import { Experience, Photo, ScheduledExperience } from "../../api/v0/ui-model"
import useWindowSize from "../../layout/hooks/use-window-size"
import { getAdventurePagePath } from "../../pages/routing/routes"

interface ExperienceCardProps {
	context: "adventure" | "discover" | "favorites"
	experience: Experience | ScheduledExperience
	tripId: string
	coverImageXYRatio?: number
	/** A CSS color to be used for per-day color coding */
	colorCodeBorder?: string
	/** A CSS color to be used for per-day color coding */
	colorCodeBackground?: string
	onFavorite: (experienceId: string) => void | Promise<void>
	onUnfavorite: (experienceId: string) => void | Promise<void>
	onHide: (experienceId: string) => void | Promise<void>
	onUnhide: (experienceId: string) => void | Promise<void>
	/**
	 * Optimization to (mostly) avoid a jitter when setting the experience card
	 * cover photo height based on the aspect ratio
	 */
	cachedExpCardCoverPhotoHeight?: string
	setCachedExpCardCoverPhotoHeight: (height?: string) => void
	children?: ReactNode
}

const ExperienceCard: FC<ExperienceCardProps> = props => {
	return (
		<div key={props.experience.name} className="experience">
			<IonCard className="content-card">
				<IonCardContent className="visual-card-content-top">
					<IonRouterLink
						routerLink={`/${getAdventurePagePath(props.tripId)}/experience/${props.experience.id}`}
					>
						<ExperiencePhoto
							{...props.experience.photos[0]}
							aspectRatio={props.coverImageXYRatio}
							cachedExpCardCoverPhotoHeight={props.cachedExpCardCoverPhotoHeight}
							setCachedExpCardCoverPhotoHeight={props.setCachedExpCardCoverPhotoHeight}
						/>
					</IonRouterLink>
					<div className="content-card-actions">
						<ExperienceActions
							experienceId={props.experience.id}
							myPreference={props.experience.myPreference}
							onFavorite={props.onFavorite}
							onUnfavorite={props.onUnfavorite}
							onHide={props.onHide}
							onUnhide={props.onUnhide}
						/>
					</div>
					{props.children && <div className="content-card-extras">{props.children}</div>}
				</IonCardContent>

				<IonCardHeader>
					<div className="content-card-header">
						<Header {...props} />
					</div>
				</IonCardHeader>
			</IonCard>
		</div>
	)
}

const Header: FC<ExperienceCardProps> = props => {
	const { experience, context } = props
	return (
		<>
			{context === "adventure" ? (
				<p className="experience-card-time-info">
					{experience.duration}
					{/* {(experience as ScheduledExperience).startTime}
					{experience.duration && <>&nbsp;&nbsp;•&nbsp;&nbsp;{experience.duration}</>} */}
				</p>
			) : (
				<p className="experience-card-time-info">{experience.duration}</p>
			)}
			<div className="experience-card-header-main">
				<IonCardTitle className="medium-title">
					<IonRouterLink routerLink={`/${getAdventurePagePath(props.tripId)}/experience/${experience.id}`}>
						{experience.name}
					</IonRouterLink>
				</IonCardTitle>
				{/* <IonCardSubtitle className="normal-text">
					{experience.description}
				</IonCardSubtitle> */}
			</div>
			<p className="experience-card-place-info">
				<>{experience.cost != undefined ? <>{experience.cost}&nbsp;&nbsp;&nbsp;</> : ""}</>
				{experience.rating0To5 != undefined && <strong>★&nbsp;{experience.rating0To5}</strong>}
			</p>
		</>
	)
}

export interface ExperienceActionProps {
	experienceId: string
	myPreference: Experience["myPreference"]
	onFavorite: (experienceId: string) => void | Promise<void>
	onUnfavorite: (experienceId: string) => void | Promise<void>
	onHide?: (experienceId: string) => void | Promise<void>
	onUnhide?: (experienceId: string) => void | Promise<void>
}

export const ExperienceActions: FC<ExperienceActionProps> = props => {
	if (typeof props.onUnfavorite === "function") {
		return (
			<>
				{props.myPreference === "favorite" ? (
					<IonButton fill="clear" onClick={() => props.onUnfavorite?.(props.experienceId)}>
						<div slot="icon-only">
							<FontAwesomeIcon fontSize={"24px"} icon={faHeartSolid} />
						</div>
					</IonButton>
				) : (
					<IonButton fill="clear" onClick={() => props.onFavorite?.(props.experienceId)}>
						<div slot="icon-only">
							<FontAwesomeIcon fontSize={"24px"} icon={faHeartReg} />
						</div>
					</IonButton>
				)}
			</>
		)
	}
	return <></>
}

export const ExperiencePhoto: FC<
	| (Photo & {
			aspectRatio?: number
			cachedExpCardCoverPhotoHeight?: string
			setCachedExpCardCoverPhotoHeight?: (height: string) => void
	  })
	| undefined
> = photo => {
	const ref = useRef<HTMLDivElement>(null)
	const [height, setHeight] = useState<string>()
	const windowSize = useWindowSize()

	useLayoutEffect(() => {
		if (
			typeof photo?.aspectRatio === "number" &&
			photo?.cachedExpCardCoverPhotoHeight &&
			height != photo.cachedExpCardCoverPhotoHeight
		) {
			setHeight(photo.cachedExpCardCoverPhotoHeight)
		}
		let allowedTries = 100
		function trySetHeight() {
			allowedTries--
			if (ref?.current?.offsetWidth) {
				const newHeight = `${ref.current.offsetWidth / +(photo?.aspectRatio ?? 1)}px`
				setHeight(newHeight)
				photo?.setCachedExpCardCoverPhotoHeight?.(newHeight)
			} else if (allowedTries > 0) {
				setTimeout(trySetHeight)
			}
		}
		if (typeof photo?.aspectRatio === "number") {
			trySetHeight()
		}
	}, [ref, windowSize])

	const coverImgStyle = {
		backgroundImage: `url(${photo?.src})`,
		height: height,
	}

	return photo && photo.src ? (
		<div ref={ref} className="experience-image" role="img" aria-label={photo.altText} style={coverImgStyle}></div>
	) : (
		<div ref={ref} className="experience-image image-not-available" style={{ height }}>
			Image not available
		</div>
	)
}

export default ExperienceCard
