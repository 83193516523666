import { faMapLocationDot, faPhotoFilm, faStar, faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonButton, IonButtons } from "@ionic/react"
import { FC } from "react"
import { Trip, TripSchedule } from "../../api/v0/ui-model"
import Masthead, { MastheadProps } from "../../components/header/Masthead"
import ViewToggle from "../../components/header/ViewToggle"
import { TripPageProps } from "../model"
import { getAdventurePagePath } from "../routing/routes"

interface TabMastheadProps extends MastheadProps, TripPageProps {
	showMapViewToggle: boolean
	mapOrListView: "map" | "list"
	setMapOrListView: (val: "map" | "list") => void
	background: "transparent" | "frosted"
	currentSchedule: TripSchedule
	loadingCurrentSchedule: boolean
	refreshCurrentSchedule: (eagerUpdate?: TripSchedule | undefined) => void
	trip: Trip
	isPortraitLayout: boolean
}

const TabMasthead: FC<TabMastheadProps> = ({
	user,
	userLoading,
	background,
	showMapViewToggle,
	mapOrListView,
	setMapOrListView,
	currentSchedule,
	isPortraitLayout,
	showBackButton,
	toggleTripFeedbackForm,
	trip,
}) => {
	const canEdit = user?.id === currentSchedule.ownerUserId

	return (
		<Masthead
			user={user}
			userLoading={userLoading}
			background={background}
			showBackButton={showBackButton}
			getBackButtonDefaultHref={() => (trip?.id ? `/${getAdventurePagePath(trip?.id)}` : "/")}
		>
			<IonButtons slot="end">
				{currentSchedule.status === "done" && canEdit && (
					<IonButton id="open-trip-feedback" onClick={() => toggleTripFeedbackForm(false)}>
						<div className="icon-button vertical">
							<FontAwesomeIcon icon={faStar} style={{ fontSize: "15px" }} />
							<span className="small-label">Rate</span>
						</div>
					</IonButton>
				)}

				{currentSchedule.status === "done" && isPortraitLayout && canEdit && (
					<IonButton
						id="open-trip-feedback"
						onClick={() => {
							console.log(currentSchedule.userRating)
							toggleTripFeedbackForm(!!currentSchedule.userRating)
						}}
					>
						<div className="icon-button vertical">
							<FontAwesomeIcon icon={faWandMagicSparkles} style={{ fontSize: "15px" }} />
							<span className="small-label">Modify</span>
						</div>
					</IonButton>
				)}

				{showMapViewToggle && (
					<ViewToggle onToggle={() => setMapOrListView(mapOrListView === "map" ? "list" : "map")}>
						<div className="icon-button vertical">
							{mapOrListView === "list" ? (
								<>
									<FontAwesomeIcon icon={faMapLocationDot} style={{ fontSize: "18px" }} />
									<span className="small-label">Map</span>
								</>
							) : (
								<>
									<FontAwesomeIcon icon={faPhotoFilm} style={{ fontSize: "18px" }} />
									<span className="small-label">Timeline</span>
								</>
							)}
						</div>
					</ViewToggle>
				)}
			</IonButtons>
		</Masthead>
	)
}

export default TabMasthead
