import { useEffect, useState } from "react"
import apiGetTrips from "../../../api/v0/trips/get"
import { Trip } from "../../../api/v0/ui-model"
import { User } from "../../../auth/model"

export default (user: User | undefined): [Trip[], Trip[], (page: number) => Promise<void>, Loading, Error] => {
	const [tripsByPage, setTripsByPage] = useState<Record<number, Trip[]>>({})
	const [loading, setLoading] = useState(true)
	const [currentPage, setCurrentPage] = useState(1)
	const [error, setError] = useState<Error>(undefined)

	const load = async (page = 1) => {
		setCurrentPage(page)
		try {
			setTripsByPage({
				...tripsByPage,
				[page]: await getTrips(page),
			})
		} catch (e) {
			console.error("error caught in use-user-trips:", e)
			setError(e)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		load()
		return () => {
			setTripsByPage({})
			setError(undefined)
		}
	}, [user])

	return [Object.values(tripsByPage).flatMap(trips => trips), tripsByPage[currentPage], load, loading, error]
}

const getTrips = async function (page: number) {
	return await apiGetTrips(page)
}

type Loading = boolean
type Error = unknown
