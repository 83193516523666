import { IonButton, IonContent, IonPage, IonTitle } from "@ionic/react"
import { FC } from "react"
import Masthead from "../header/Masthead"
import { PageProps } from "../../pages/model"

const NotFoundPage: FC<PageProps> = ({ user, userLoading }: PageProps) => (
	<IonPage>
		<Masthead user={user} userLoading={userLoading} background="frosted"></Masthead>
		<IonContent>
			<IonButton routerLink="/">Go home</IonButton>
		</IonContent>
	</IonPage>
)

export default NotFoundPage
