import { post } from "../../../../api-utils"
import { ApiTripPlan } from "../../../api-model"
import { apiTripPlanToTrip, tripFeedbackToApiTripFeedback } from "../../../mapping"
import { Trip, TripFeedback } from "../../../ui-model"

export default async function (tripId: string, feedback: TripFeedback): Promise<Trip> {
	const tripPlan = await post<ApiTripPlan>(`v0/trips/${tripId}/versions`, tripFeedbackToApiTripFeedback(feedback))
	if (!tripPlan) throw new Error(`POST /trips: response body was ${tripPlan}`)
	return apiTripPlanToTrip(tripPlan)
}
