import { get } from "../../api-utils"
import { User } from "../../../auth/model"

interface ApiUser {
	account_id: string
	email_address: string
	name: string
	status: number
	user_id: number
}

export default async function (): Promise<User> {
	const apiUser = await get<ApiUser>(`v0/auth/user`)
	if (!apiUser) throw new Error(`GET /v0/auth/user: response body was ${apiUser}`)
	return {
		id: `${apiUser.user_id}`,
		name: apiUser.name,
	}
}
