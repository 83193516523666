import { faMountain, faPersonHiking, faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonCol,
	IonContent,
	IonGrid,
	IonPage,
	IonPopover,
	IonRow,
	IonSpinner,
	useIonRouter,
	useIonToast,
} from "@ionic/react"
import { FC, useState } from "react"
import { useParams } from "react-router"
import Masthead from "../../components/header/Masthead"
import { PageProps } from "../model"
import { getAdventureTabPath } from "../routing/routes"
import postPaymentPromoCode from "../../api/v0/payment/promo-code/post"
import logError from "../../error-handling/log-error"

interface PayWallPageProps extends PageProps {}

const PayWallPage: FC<PayWallPageProps> = ({ user, userLoading }) => {
	const [promoCode, setPromoCode] = useState("")
	const { tripId } = useParams<{ tripId: string }>()
	const router = useIonRouter()
	const [presentToast] = useIonToast()
	const [submitted, setSubmitted] = useState(false)

	async function tryRedeemPromoCode(): Promise<void> {
		setSubmitted(true)
		try {
			await postPaymentPromoCode(promoCode, tripId)
			setTimeout(() => {
				router.push(`/${getAdventureTabPath(tripId)}`)
			}, 500)
		} catch (e) {
			logError(e)
			presentToast({
				message: "Not a valid promo code — check that it was entered correctly",
				duration: 5000,
			})
		} finally {
			setSubmitted(false)
		}
	}

	return (
		<IonPage id="pay-wall-page" className="light-bg">
			<Masthead
				user={user}
				userLoading={userLoading}
				background="transparent"
				showBackButton={false}
				largeLogoAlways={true}
			/>
			<IonContent>
				<IonGrid className="page-max-width">
					<IonRow>
						<IonCol>
							<h1>Choose your tier</h1>
						</IonCol>
					</IonRow>
					<IonRow style={{ marginBottom: "10px" }}>
						<IonCol>
							<div id="redeem-promo-code" className="cscd-input">
								<input
									placeholder="or use a promo code"
									onInput={e => setPromoCode((e.target as HTMLInputElement).value ?? "")}
									onKeyDown={e => e.key === "Enter" && tryRedeemPromoCode()}
								/>
								<IonButton id="show-tooltip" fill="clear">
									<div slot="icon-only">
										<FontAwesomeIcon icon={faQuestionCircle} />
									</div>
								</IonButton>
								<IonPopover id="promo-code-tooltip" trigger="show-tooltip" triggerAction="click">
									<p>Enter a promo code to redeem a credit for 1 basic adventure.</p>
								</IonPopover>
								<IonButton
									className="bold-button input-button-append"
									disabled={promoCode?.length < 4}
									onClick={() => tryRedeemPromoCode()}
								>
									{submitted ? <IonSpinner name="dots" /> : "Redeem"}
								</IonButton>
							</div>
						</IonCol>
					</IonRow>
					<IonRow className="pay-wall-features">
						<IonCol size="6">
							<IonCard className="info-card">
								<IonCardHeader>
									<IonCardTitle>
										<FontAwesomeIcon icon={faPersonHiking} />
										<h2 className="small-title">
											<strong>Basic Adventure</strong>
										</h2>
									</IonCardTitle>
								</IonCardHeader>
								<IonCardContent>
									<p className="original-price">
										<b>$9.99</b>
									</p>
									<p className="small-label">
										<b>Early access price:</b>
									</p>
									<p className="large-number sale-price">
										<sup>$</sup>5.99
									</p>
									<p>A rich itinerary with photos, ratings, directions, and more</p>
									<p>View photos, reviews, and more for each stop on your adventure</p>
									<p>Modify your itinerary to craft your perfect adventure</p>
									<p>Share with anyone</p>
								</IonCardContent>
								<IonButton disabled className="bold-button coming-soon">
									Coming Soon
								</IonButton>
							</IonCard>
						</IonCol>
						<IonCol size="6">
							<IonCard className="info-card premium-adventure">
								<IonCardHeader>
									<IonCardTitle>
										<FontAwesomeIcon icon={faMountain} />
										<h2 className="small-title">
											<strong>Premium Adventure</strong>
										</h2>
									</IonCardTitle>
								</IonCardHeader>
								<IonCardContent>
									<p className="large-number">
										<sup>$</sup>19.99
									</p>
									<p>Everything in the basic tier, plus:</p>
									<p>Set a budget and book your adventure</p>
									<p>Get real-time travel and weather updates</p>
									<p>Collaborate on trips</p>
									<p>Export to Google Calendar</p>
									<p>Attach photos and notes from your adventure</p>
								</IonCardContent>
								<IonButton disabled className="bold-button coming-soon">
									Coming Soon
								</IonButton>
							</IonCard>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	)
}

export default PayWallPage
