import { IonCol, IonContent, IonGrid, IonItem, IonLabel, IonPage, IonRow, IonThumbnail } from "@ionic/react"
import { FC } from "react"
import Masthead from "../../components/header/Masthead"
import { PageProps } from "../model"
import "./AboutPage.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDiscord, faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

interface AboutPageProps extends PageProps {}

const AboutPage: FC<AboutPageProps> = ({ user, userLoading }) => {
	return (
		<IonPage id="about-page" className="light-bg">
			<Masthead user={user} userLoading={userLoading} background="frosted" largeLogoAlways={true} />
			<IonContent>
				<IonGrid className="page-max-width">
					<IonRow>
						<IonCol>
							<article>
								<div className="ion-padding">
									<h2 className="larger-title">The Vision</h2>
									<p>
										Cascade is on a mission to empower everyone to explore, embrace, and share the
										wonders of our world with confidence. To us, that means harnessing the awesome,
										untapped power of generative AI, supercharging it with rich travel data from
										across the web, and delivering a personalized, responsive, delightful travel
										agent that anyone can enjoy using.
									</p>
								</div>

								<h2 className="ion-padding larger-title">The Team</h2>
								<div>
									<IonItem lines="none" className="team-member">
										<IonThumbnail slot="start">
											<img
												alt="Drew Vanderriet"
												src="https://storage.googleapis.com/cascade_travel_assets/headshot_drew.png"
											/>
										</IonThumbnail>
										<h3>Drew Vanderriet, Founder</h3>
									</IonItem>
									<p className="ion-padding">
										Drew is a veteran to the startup space, having started his first company, Touch
										Prosthetics during his masters in bio-mechatronics. He has a wide range of
										skills and experience across all the key areas required in Cascade, having
										previously worked on Siri at Apple, run the product and business development for
										a SaaS fintech initiative at LSQ, and has over 10 years of engineering
										leadership experience. He also has traveled extensively, doing full time for two
										years while teaching English in Asia, Europe, and South America. His knowledge
										of travel, language and prompt engineering, technology, product development, and
										entrepreneurship allow him to thread the needle in technical advancements that
										directly meet the market needs. He is passionate about leveraging these skills
										to advance society little by little.
									</p>
								</div>

								<br />
								<br />

								<div>
									<IonItem lines="none" className="team-member">
										<IonThumbnail slot="start">
											<img
												alt="Don Heins"
												src="https://storage.googleapis.com/cascade_travel_assets/headshot_don.png"
											/>
										</IonThumbnail>
										<h3>Don Heins, Founding Engineer</h3>
									</IonItem>
									<p className="ion-padding">
										With six years of professional experience in designing and implementing
										financing software and leading engineering teams, Don holds a Master of Computer
										Science from DePaul University, complemented by a Bachelor of Science in
										Meteorology from Northern Illinois University. His diverse background and
										technical expertise make them a valuable addition to the team. Outside of his
										professional life, his passion for travel and spending quality time with his
										wife and two kids reflects his commitment to personal growth and experiencing
										different cultures, bringing a unique perspective to the team's efforts in
										advancing the project.
									</p>
								</div>

								<br />
								<br />

								<div>
									<IonItem lines="none" className="team-member">
										<IonThumbnail slot="start">
											<img
												alt="Danny Mayer"
												src="https://storage.googleapis.com/cascade_travel_assets/headshot_danny.jpg"
											/>
										</IonThumbnail>
										<h3>Danny Mayer, Founding Engineer</h3>
									</IonItem>
									<p className="ion-padding">
										Danny has nearly a decade of experience designing and building user interfaces
										and engineering full-stack web applications across a variety of industries. With
										an education in the humanities and a background in graphic design, Danny brings
										a sophisticated understanding of both aesthetic and technical design to the
										team, which he hopes will help Cascade deliver its best-in-class user
										experience. He lives in Ann Arbor, Michigan with his wife and two dogs.
									</p>
								</div>

								<br />
								<br />

								<div>
									<IonItem lines="none" className="team-member">
										<IonThumbnail slot="start">
											<img
												alt="Esha Shanbhogue"
												src="https://storage.googleapis.com/cascade_travel_assets/headshot_esha.jpg"
											/>
										</IonThumbnail>
										<h3>Esha Shanbhogue, Founding Product Strategist</h3>
									</IonItem>
									<p className="ion-padding">
										Esha stands out as a seasoned project manager with a dynamic blend of technical
										expertise and strategic leadership. She has over nine years of product
										development experience across multiple industries including entertainment,
										technology, and automotive. Esha has an MBA from the University of North
										Carolina at Chapel Hill and a B.S. in Mechanical and Biomedical Engineering from
										Carnegie Mellon University. Esha’s unique blend of skills and knowledge
										positions her exceptionally well to devise and implement product strategies that
										resonate with the market while pushing the boundaries of technological
										advancement. Outside of her professional life, Esha enjoys traveling and eating
										adventurously and spending time with her husband and two dogs.
									</p>
								</div>
							</article>

							<div className="ion-padding">
								<div className="socials">
									<a target="_blank" href="https://discord.gg/e9rUtHNNSG">
										<FontAwesomeIcon icon={faDiscord as IconProp} />
										<span className="sr-only">Discord</span>
									</a>
									<a target="_blank" href="https://www.instagram.com/cascadetravel.app">
										<FontAwesomeIcon icon={faInstagram as IconProp} />
										<span className="sr-only">Instagram</span>
									</a>
									<a target="_blank" href="https://www.facebook.com/cascadetravelapp">
										<FontAwesomeIcon icon={faFacebook as IconProp} />
										<span className="sr-only">Facebook</span>
									</a>
								</div>
								<br />
								<p>
									Have notes?{" "}
									<a
										target="_blank"
										href="https://docs.google.com/forms/d/e/1FAIpQLSfVymVDCR_-McCX5ClyFKvd6OCYNkcrvKnDYLIkcBnqEKpyXw/viewform"
									>
										Give us feedback
									</a>
								</p>
								<p>
									Curious? Discover answers to{" "}
									<a
										target="_blank"
										href="https://storage.googleapis.com/cascade_travel_assets/Cascade%20FAQ.pdf"
									>
										frequently asked questions
									</a>
								</p>
								<p>
									Still have questions? Email us at{" "}
									<a target="_blank" href="mailto:support@cascadetravel.app">
										support@cascadetravel.app
									</a>
								</p>
							</div>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	)
}

export default AboutPage
