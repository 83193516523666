import {
	IonButtons,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonContent,
	IonGrid,
	IonPage,
	IonRouterLink,
	IonRow,
	IonSkeletonText,
	IonThumbnail,
} from "@ionic/react"
import { debounce } from "lodash"
import { FC, useEffect } from "react"
import { useParams } from "react-router"
import putExperiencePref from "../../../api/v0/trips/_code_/experience/_id_/put"
import { ExperienceDetail, TripSchedule } from "../../../api/v0/ui-model"
import { ExperienceActions, ExperiencePhoto } from "../../../components/experience/ExperienceCard"
import Masthead from "../../../components/header/Masthead"
import NotFoundPage from "../../../components/not-found/NotFound"
import Slides from "../../../components/slides/Slides"
import logError from "../../../error-handling/log-error"
import { MOBILE_LAYOUT_MAX_WIDTH_PX } from "../../../layout/config"
import useWindowSize from "../../../layout/hooks/use-window-size"
import { TripPageProps } from "../../model"
import { RouteParams } from "../../routing/route-params"
import useExperienceDetail from "../hooks/use-experience-detail"
import { getAdventurePagePath } from "../../routing/routes"

interface ExperienceDetailProps extends Omit<TripPageProps, "experiences"> {
	currentSchedule: TripSchedule
}

const ExperienceDetailPage: FC<ExperienceDetailProps> = props => {
	const { experienceId } = useParams<RouteParams>()
	const windowSize = useWindowSize()
	const isPortraitLayout = (windowSize.width ?? 0) <= MOBILE_LAYOUT_MAX_WIDTH_PX

	if (!experienceId) return <NotFoundPage {...props} />

	const [expDetail, refresh, loading, error] = useExperienceDetail(props.trip.id, experienceId, props.user)

	if (error) {
		console.error("error caught in exp detail", error)
		throw error
	}

	return (
		<>
			<Masthead
				user={props.user}
				userLoading={props.userLoading}
				background="frosted"
				showBackButton={true}
				hideLogo={true}
				getBackButtonDefaultHref={() => (props.trip?.id ? `/${getAdventurePagePath(props.trip?.id)}` : "/")}
			>
				<IonButtons slot="end">
					{expDetail && (
						<ExperienceActions
							experienceId={experienceId}
							myPreference={expDetail.myPreference}
							onFavorite={() => updateExperiencePref("favorite")}
							onUnfavorite={() => updateExperiencePref("neutral")}
						/>
					)}
				</IonButtons>
			</Masthead>
			<IonContent fullscreen className="exp-detail-content">
				<div className="exp-detail-inner page-max-width">
					{loading || !expDetail ? (
						<div id="exp-detail-main" className="content-loading">
							<IonThumbnail>
								<IonSkeletonText animated />
							</IonThumbnail>
							<h2>
								<IonSkeletonText animated />
							</h2>
							<p>
								<IonSkeletonText animated />
							</p>
							<p>
								<IonSkeletonText animated />
							</p>
						</div>
					) : (
						<>
							<div id="exp-detail-main">
								<div className={isPortraitLayout ? "full-width-col" : ""}>
									<IonCard className="title-card">
										<IonCardHeader
											style={{
												backgroundImage: `url(${expDetail.photos[0]?.src ?? ""})`,
												backgroundSize: "cover",
											}}
										>
											<div className="title-card-text">
												<IonCardTitle>
													<h1>{expDetail.name}</h1>
												</IonCardTitle>
												<IonCardSubtitle className="small-title">
													{expDetail.rating0To5 != undefined ? (
														<strong>★&nbsp;{expDetail.rating0To5}</strong>
													) : (
														""
													)}
													{expDetail.cost && <>&nbsp;&nbsp;&nbsp;{expDetail.cost}</>}
												</IonCardSubtitle>
											</div>
										</IonCardHeader>
									</IonCard>
								</div>

								{isPortraitLayout && (
									<Slides>
										{expDetail.photos?.map((photo, i) => (
											<ExperiencePhoto key={photo.src} {...photo} />
										))}
									</Slides>
								)}

								<IonCard className="info-card">
									<IonCardContent>
										<h3>Address</h3>
										<p>{expDetail.address}</p>

										<h3>Description</h3>
										<p>{expDetail.description}</p>

										<h3>Website</h3>
										<p>
											<IonRouterLink href={expDetail.website} target="_blank">
												{expDetail.website}
											</IonRouterLink>
										</p>

										<h3>
											Reviews from
											<GoogleLogoSvg />
											<span className="sr-only">Google</span>
										</h3>
										{expDetail.reviews.map((review, index) => (
											<section key={index}>
												<p>
													{review.authorName} rated this&nbsp;
													<strong>{review.rating0To5} stars</strong>
													<br />
												</p>
												<blockquote>
													<p>{review.text}</p>
												</blockquote>
											</section>
										))}
									</IonCardContent>
								</IonCard>

								{/* FIXME: We need geolocation from the backend for this to work */}
								{/* <ExperienceMap
									experiences={[{
										uniqueId: expDetail.experienceId,
										id: expDetail.experienceId,
										thirdPartyId: "",
										name: expDetail.name,
										description: expDetail.description,
										photos: expDetail.photos,
										myPreference: expDetail.myPreference,
										address: expDetail.address,
										rating0To5: expDetail.rating0To5,
										cost: expDetail.cost,
										url: expDetail.website,
										duration: "",
										// geolocation: expDetail.geolocation,
									}]}
									selectedExperienceId={expDetail.experienceId}
									getPinIconUrl={() => `/assets/map-pins/pin-default.png`}
									onMarkerClick={() => {}}
								/> */}
							</div>

							{!isPortraitLayout && (
								<IonGrid id="exp-detail-gallery">
									<IonRow>
										{expDetail.photos?.map((photo, i) => (
											<IonCol key={photo.src + i} size="12">
												<ExperiencePhoto {...photo} />
											</IonCol>
										))}
									</IonRow>
								</IonGrid>
							)}
						</>
					)}
				</div>
			</IonContent>
		</>
	)

	function updateExperiencePref(prefType: ExperienceDetail["myPreference"]): void {
		refresh({
			...expDetail!,
			myPreference: prefType,
		})
		commitExperiencePref(props, refresh, experienceId!, prefType)
	}
}

export default ExperienceDetailPage

const GoogleLogoSvg = ({ useCurrentColor = false }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		height="14"
		width="30"
		viewBox="-40.446 -22.19 350.532 133.14"
		style={{ transform: "scale(2) translateX(-1px)", transformOrigin: "left 30%" }}
	>
		<path
			d="M115.39 46.71c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18c0-12.86 9.99-22.18 22.25-22.18s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44s-12.51 5.46-12.51 13.44c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"
			fill={useCurrentColor ? "currentColor" : "#EA4335"}
		/>
		<path
			d="M163.39 46.71c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18c0-12.85 9.99-22.18 22.25-22.18s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44s-12.51 5.46-12.51 13.44c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"
			fill={useCurrentColor ? "currentColor" : "#FBBC05"}
		/>
		<path
			d="M209.39 25.87v39.82c0 16.38-9.66 23.07-21.08 23.07-10.75 0-17.22-7.19-19.66-13.07l8.48-3.53c1.51 3.61 5.21 7.87 11.17 7.87 7.31 0 11.84-4.51 11.84-13v-3.19h-.34c-2.18 2.69-6.38 5.04-11.68 5.04-11.09 0-21.25-9.66-21.25-22.09 0-12.52 10.16-22.26 21.25-22.26 5.29 0 9.49 2.35 11.68 4.96h.34v-3.61h9.25zm-8.56 20.92c0-7.81-5.21-13.52-11.84-13.52-6.72 0-12.35 5.71-12.35 13.52 0 7.73 5.63 13.36 12.35 13.36 6.63 0 11.84-5.63 11.84-13.36z"
			fill={useCurrentColor ? "currentColor" : "#4285F4"}
		/>
		<path d="M224.64 2.53v65h-9.5v-65z" fill={useCurrentColor ? "currentColor" : "#34A853"} />
		<path
			d="M261.66 54.01l7.56 5.04c-2.44 3.61-8.32 9.83-18.48 9.83-12.6 0-22.01-9.74-22.01-22.18 0-13.19 9.49-22.18 20.92-22.18 11.51 0 17.14 9.16 18.98 14.11l1.01 2.52-29.65 12.28c2.27 4.45 5.8 6.72 10.75 6.72 4.96 0 8.4-2.44 10.92-6.14zm-23.27-7.98l19.82-8.23c-1.09-2.77-4.37-4.7-8.23-4.7-4.95 0-11.84 4.37-11.59 12.93z"
			fill={useCurrentColor ? "currentColor" : "#EA4335"}
		/>
		<path
			d="M34.93 40.94v-9.41h31.71c.31 1.64.47 3.58.47 5.68 0 7.06-1.93 15.79-8.15 22.01-6.05 6.3-13.78 9.66-24.02 9.66C15.96 68.88 0 53.42 0 34.44 0 15.46 15.96 0 34.94 0c10.5 0 17.98 4.12 23.6 9.49l-6.64 6.64c-4.03-3.78-9.49-6.72-16.97-6.72-13.86 0-24.7 11.17-24.7 25.03 0 13.86 10.84 25.03 24.7 25.03 8.99 0 14.11-3.61 17.39-6.89 2.66-2.66 4.41-6.46 5.1-11.65z"
			fill={useCurrentColor ? "currentColor" : "#4285F4"}
		/>
	</svg>
)

const commitExperiencePref = debounce(
	(
		props: TripPageProps,
		refresh: (eagerUpdate?: ExperienceDetail | undefined) => void,
		experienceId: string,
		prefType: ExperienceDetail["myPreference"],
	) => {
		putExperiencePref(props.trip.id, experienceId!, prefType)
			.then(() => {
				refresh()
				props.refreshCurrentSchedule()
				// props.refreshDiscover()
				props.refreshFavorites()
			})
			.catch(logError)
	},
	2000,
)
