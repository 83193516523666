import { post } from "../../api-utils"
import { ApiTripPlan } from "../api-model"
import { apiTripPlanToTrip, tripInputToApiTripRequest } from "../mapping"
import { Trip, TripInput } from "../ui-model"

export default async function (input: TripInput): Promise<Trip> {
	const tripPlan = await post<ApiTripPlan>(`v0/trips`, tripInputToApiTripRequest(input))
	if (!tripPlan) throw new Error(`POST /trips: response body was ${tripPlan}`)
	return apiTripPlanToTrip(tripPlan)
}
