import { get } from "../../../api-utils"
import { ApiTripPlan } from "../../api-model"
import { apiTripPlanToTrip } from "../../mapping"
import { Trip } from "../../ui-model"

export default async function (page: number): Promise<Trip[]> {
	const trips = await get<ApiTripPlan[]>(`v0/trips/community`, { page })
	if (!trips) throw new Error(`GET /trips/community: response body was ${trips}`)
	return trips.map(apiTripPlanToTrip)
}
