import { faMagicWandSparkles, faStar } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IonButton, IonContent, IonLabel, IonSpinner, IonTextarea, UseIonToastResult, useIonToast } from "@ionic/react"
import { FC, useEffect, useRef, useState } from "react"
import postLatestTripVersionRating from "../../api/v0/trips/_code_/versions/latest/rating/post"
import postTripVersions from "../../api/v0/trips/_code_/versions/post"
import { Trip, TripSchedule } from "../../api/v0/ui-model"
import logError from "../../error-handling/log-error"
import useWindowSize from "../../layout/hooks/use-window-size"
import { MOBILE_LAYOUT_MAX_WIDTH_PX } from "../../layout/config"

export interface FeedbackFormProps {
	trip: Trip
	currentSchedule: TripSchedule
	refreshCurrentSchedule: (eagerUpdate?: TripSchedule | undefined) => void
	dismiss: (data?: string | null | undefined, role?: string | undefined) => Promise<boolean>
	skipRating: boolean
	onInputFocus: () => void
	onInputBlur: () => void
}

const FeedbackForm: FC<FeedbackFormProps> = props => {
	const input = useRef<HTMLIonTextareaElement>(null)
	const [rating, setRating] = useState<number | undefined>(props.currentSchedule.userRating)
	const [ratingCompleted, setRatingCompleted] = useState(props.skipRating)
	const [requestForChanges, setRequestForChanges] = useState("")
	const [submitting, setSubmitting] = useState(false)
	const { width } = useWindowSize()

	function submitRating(ratingOneToFive: number): void {
		setRating(ratingOneToFive)
		if (ratingOneToFive !== undefined) {
			setTimeout(() => setRatingCompleted(true), 250)
			props.refreshCurrentSchedule({ ...props.currentSchedule, userRating: ratingOneToFive })
			postLatestTripVersionRating(props.trip.id, { ratingOneToFive })
				.catch(logError)
				.finally(() => {
					props.refreshCurrentSchedule()
				})
		}
	}

	function submitRequestForChanges(): void {
		if (!submitting) {
			setSubmitting(true)

			postTripVersions(props.trip.id, { feedback: requestForChanges }).then(() => {
				setSubmitting(false)
				props.dismiss(input.current?.value, "confirm")
				props.refreshCurrentSchedule()
			})
		}
	}

	return (
		<IonContent id="trip-feedback-modal" className="trip-feedback-main light-bg">
			<div id="trip-feedback-carousel">
				<div id="trip-feedback-rating" className={`${ratingCompleted ? "completed" : ""}`}>
					<div className="page-max-width">
						<div className="ion-padding">
							<h2 className="small-title">Rate this version of your overall adventure</h2>
						</div>
						{[1, 2, 3, 4, 5].map(n => (
							<IonButton key={n} onClick={() => submitRating(n)} fill="clear">
								<div slot="icon-only">
									<FontAwesomeIcon
										icon={faStar}
										style={{
											color:
												(rating ?? 0) >= n
													? "var(--cscd-color-primary-dark)"
													: "var(--cscd-color-text-gray)",
										}}
									/>
								</div>
							</IonButton>
						))}
					</div>
				</div>
				<div id="trip-feedback-prompt" className="ion-padding">
					<div className="page-max-width">
						<IonButton id="trip-feedback-back" fill="clear" onClick={() => setRatingCompleted(false)}>
							<IonLabel slot="icon-only" className="small-label-header">
								{[1, 2, 3, 4, 5].map(n => (
									<FontAwesomeIcon
										key={n}
										icon={faStar}
										style={{
											color:
												(rating ?? 0) >= n ? "var(--cscd-color-primary-dark)" : "transparent",
										}}
									/>
								))}
							</IonLabel>
						</IonButton>
						<div className="cscd-input">
							<IonTextarea
								id="trip-feedback-prompt-input"
								ref={input}
								placeholder={`Tell us how to improve this trip. For example: "on day 2 I want spas and relaxation only"`}
								onInput={e => setRequestForChanges((e.target as unknown as { value: string }).value)}
								onFocus={() => props.onInputFocus()}
								onBlur={() => props.onInputBlur()}
							/>
						</div>
						<div>
							<button
								className="bold-button"
								disabled={!requestForChanges?.length || submitting}
								onClick={() => submitRequestForChanges()}
							>
								{submitting ? (
									<IonSpinner name="dots" style={{ width: "18px", height: "18px" }} />
								) : (
									<span>
										<FontAwesomeIcon icon={faMagicWandSparkles} />
										&nbsp;&nbsp; Re-plan this adventure
									</span>
								)}
							</button>
						</div>
					</div>
				</div>
			</div>
		</IonContent>
	)
}

export default FeedbackForm
