import { faClock, faDiamondTurnRight, faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonContent,
	IonGrid,
	IonRouterLink,
	IonRow,
	IonSkeletonText,
	IonThumbnail,
} from "@ionic/react"
import { debounce } from "lodash"
import { FC, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import Swiper from "swiper"
import putExperiencePref from "../../../api/v0/trips/_code_/experience/_id_/put"
import {
	Experience,
	ScheduledExperience,
	TripDay,
	UniqueExperience,
	UniqueScheduledExperience,
} from "../../../api/v0/ui-model"
import ExperienceCard from "../../../components/experience/ExperienceCard"
import ExperienceMap from "../../../components/map/ExperienceMap"
import logError from "../../../error-handling/log-error"
import { findIndexOfClosestNumber } from "../../../helpers/array-helpers"
import { MOBILE_LAYOUT_MAX_WIDTH_PX } from "../../../layout/config"
import useWindowSize from "../../../layout/hooks/use-window-size"
import { TripPageProps } from "../../model"
import { getExperienceDetailPath } from "../../routing/routes"

interface AdventureScheduleTabProps extends TripPageProps {
	loading: boolean
	mapOrListView: "map" | "list"
	scrollPosPx: number
	setScrollPosPx: (scrollPosPx: number) => void
	carouselIndex: number
	setCarouselIndex: (carouselIndex: number) => void
	/**
	 * Optimization to (mostly) avoid a jitter when setting the experience card
	 * cover photo height based on the aspect ratio and screen dimensions
	 */
	cachedExpCardCoverPhotoHeight?: string
	setCachedExpCardCoverPhotoHeight: (height?: string) => void
}

const AdventureScheduleTab: FC<AdventureScheduleTabProps> = props => {
	const [selectedExperience, setSelectedExperience] = useState<UniqueExperience | undefined>()
	const [experienceSelectedFromMap, setExperienceSelectedFromMap] = useState<UniqueExperience | undefined>()
	const windowSize = useWindowSize()
	const swiperRef = useRef(null)
	const experienceColumnsRef = useRef<(HTMLIonColElement | null)[]>([])
	const dayRowsRef = useRef<(HTMLIonRowElement | null)[]>([])
	const [expCardOffsetTops, setExpCardOffsetTops] = useState<(number | undefined)[]>([])
	const [swiper, setSwiper] = useState<Swiper | undefined>()
	const isMapShowing = props.mapOrListView === "map" || (windowSize.width ?? 0) > MOBILE_LAYOUT_MAX_WIDTH_PX
	const isPortraitLayout = (windowSize.width ?? 0) <= MOBILE_LAYOUT_MAX_WIDTH_PX
	const allExperiences: UniqueScheduledExperience[] = useMemo(
		() =>
			props.currentSchedule?.days
				// Better to make the `filter` fn a type predicate but not sure how
				.flatMap(day =>
					day.events
						.filter(ev => ev.type === "experience")
						.map((ev, idx) => [idx, day, ev] as [number, TripDay, ScheduledExperience]),
				)
				.map(([idxWithinDay, day, ev], idx) => ({
					...ev,
					uniqueId: `day_${day.dayNumber}_${ev.id}_${idx}`,
					tripDay: day,
					experienceIndexWithinTrip: idx,
					experienceIndexWithinTripDay: idxWithinDay,
				})),
		[props.currentSchedule],
	)
	const allExperiencesInSelectedDay = useMemo(
		() =>
			selectedExperience == undefined
				? []
				: allExperiences.filter(exp => {
						return (
							(selectedExperience as UniqueScheduledExperience).tripDay.dayNumber ===
							exp.tripDay.dayNumber
						)
				  }),
		[allExperiences, selectedExperience],
	)
	const carouselSlides: (UniqueScheduledExperience | TripDay)[] = useMemo(
		() =>
			allExperiences.flatMap(tripEvent => {
				if (tripEvent.experienceIndexWithinTripDay === 0) {
					return [tripEvent.tripDay, tripEvent]
				}
				return [tripEvent]
			}),
		[allExperiences],
	)

	// Set a default "selected experience" for map view
	useEffect(() => {
		if (!selectedExperience && allExperiences.length) {
			setSelectedExperience(allExperiences[0])
		}
		props.setShowMapViewToggle(isPortraitLayout)
		if (props.currentSchedule.status !== "done") {
			props.setMapOrListView("list")
			props.setShowMapViewToggle(false)
		}
	}, [selectedExperience, allExperiences.length, isPortraitLayout, props.currentSchedule.status])

	// Whenever a map pin is clicked (i.e. when selectedExperience changes), scroll to that experience
	useLayoutEffect(() => {
		if (experienceSelectedFromMap) {
			if (isPortraitLayout) {
				const newExperienceIndex = carouselSlides.findIndex(
					exp => (exp as UniqueScheduledExperience).uniqueId === experienceSelectedFromMap.uniqueId,
				)
				if (swiper?.activeIndex !== newExperienceIndex) {
					swiper?.slideTo?.(newExperienceIndex)
					console.log("sliding to newly selected experience", swiper?.activeIndex, newExperienceIndex)
				}
			} else {
				const newExperienceIndex = allExperiences.findIndex(
					exp => exp.uniqueId === experienceSelectedFromMap.uniqueId,
				)
				const newOffsetTop = expCardOffsetTops[newExperienceIndex]
				if (newOffsetTop !== undefined) {
					contentRefLandscape.current?.scrollTo({
						top: newOffsetTop + LANDSCAPE_SAFE_AREA_TOP * 3.2 - LANDSCAPE_SAFE_AREA_TOP,
					})
					console.log(
						"scrolling to newly selected experience",
						newOffsetTop,
						newExperienceIndex,
						expCardOffsetTops,
					)
				}
			}
		}
	}, [experienceSelectedFromMap])

	const swiperSlideChangeHandler = (s: Swiper) => {
		// Save the latest carousel position
		props.setCarouselIndex(s.activeIndex)

		// In mobile view, whenever the active carousel slide changes, set the selectedExperience
		// (i.e. the highlighted map pin)
		setSelectedExperience(oldSelectedExp => {
			const newSelectedSlide = carouselSlides[s.activeIndex]
			let newSelectedExp: UniqueScheduledExperience | undefined
			if (!isTripDay(newSelectedSlide)) {
				newSelectedExp = newSelectedSlide as UniqueScheduledExperience
			}
			if (newSelectedExp) {
				if (oldSelectedExp?.uniqueId !== newSelectedExp.uniqueId) {
					console.log("slide change: setting selected exp", newSelectedExp.uniqueId)
					return newSelectedExp
				}
			}
			return oldSelectedExp
		})
	}

	// In mobile view, create/destroy the carousel whenever map view is toggled
	useEffect(() => {
		if (isPortraitLayout && swiperRef && isMapShowing) {
			setSwiper(
				new Swiper("#experience-carousel .swiper", {
					slidesPerView: "auto",
					centeredSlides: true,
					direction: "horizontal",
					spaceBetween: 15,
					// Read the latest carousel position
					initialSlide: props.carouselIndex,
					keyboard: { enabled: true },
					on: {
						slideChange: swiperSlideChangeHandler,
					},
				}),
			)
		} else if (swiper) {
			swiper.destroy?.()
			setSwiper(undefined)
		}
	}, [isMapShowing, swiperRef])

	// Save the latest scroll position information
	const contentRefPortrait = useRef<HTMLIonContentElement>(null)
	const contentRefLandscape = useRef<HTMLDivElement>(null)
	useEffect(() => {
		if (isPortraitLayout && !isMapShowing && contentRefPortrait) {
			const intervalId = setInterval(() => {
				contentRefPortrait.current?.getScrollElement().then(scrollElem => {
					props.setScrollPosPx(Math.max(scrollElem?.scrollTop ?? 0, 0))
				})
			}, 250)
			return () => clearInterval(intervalId)
		}
		if (!isPortraitLayout && contentRefLandscape) {
			const intervalId = setInterval(() => {
				const currentScrollPosPx = Math.max(contentRefLandscape.current?.scrollTop ?? 0, 0)
				props.setScrollPosPx(currentScrollPosPx)
			}, 250)
			return () => clearInterval(intervalId)
		}
	}, [contentRefPortrait, contentRefLandscape, isMapShowing, isPortraitLayout])

	// In desktop view, when the scroll position changes, select the experience that's scrolled into view
	useEffect(() => {
		if (!isPortraitLayout && contentRefLandscape && expCardOffsetTops.length) {
			setSelectedExperience(oldSelectedExp => {
				const newSelectedExpIdx = findIndexOfClosestNumber(expCardOffsetTops, props.scrollPosPx)
				const newSelectedExp = allExperiences[newSelectedExpIdx]
				if (newSelectedExp) {
					if (oldSelectedExp?.uniqueId !== newSelectedExp.uniqueId) {
						console.log(
							"scroll change: setting selected exp",
							props.scrollPosPx,
							newSelectedExpIdx,
							newSelectedExp.uniqueId,
							expCardOffsetTops,
						)
						return newSelectedExp
					}
				}
				return oldSelectedExp
			})
		}
	}, [props.scrollPosPx, expCardOffsetTops])

	// After experiences load, populate the scroll pos array
	useLayoutEffect(() => {
		if (allExperiences.length) {
			setTimeout(() => {
				setExpCardOffsetTops(
					allExperiences.map((exp, i) => {
						const dayIdx = exp.tripDay.dayNumber - 1
						const dayRowRef = dayRowsRef.current[dayIdx]
						const experienceColRef = experienceColumnsRef.current[i]
						if (dayRowRef && experienceColRef) {
							return dayRowRef.offsetTop + experienceColRef.offsetTop - LANDSCAPE_SAFE_AREA_TOP * 3.2
						}
					}),
				)
			}, 25)
		}
	}, [allExperiences.length])

	// Read the latest scroll position
	useLayoutEffect(() => {
		setTimeout(() => {
			if (isPortraitLayout) {
				if (isMapShowing) {
					contentRefPortrait.current?.scrollToPoint(0, 0)
				} else {
					contentRefPortrait.current?.scrollToPoint(0, props.scrollPosPx)
				}
			} else {
				contentRefLandscape.current?.scrollTo(0, props.scrollPosPx)
			}
		}, 25)
	}, [contentRefPortrait, props.mapOrListView, allExperiences.length])

	const getIonContentClassName = () =>
		`${isPortraitLayout ? "portrait-layout" : "landscape-layout"} ${isMapShowing ? "map-view" : "standard-view"}`

	const canEdit = props.user?.id === props.currentSchedule.ownerUserId

	return (
		<IonContent
			fullscreen
			className={"adventure-tab-content adventure-schedule-tab-content " + getIonContentClassName()}
			ref={contentRefPortrait}
			// FIXME: Causes scrolling to be f-d on iPhone
			// scrollEvents={true}
			// onIonScroll={onScroll}
			scrollY={!(isPortraitLayout && props.mapOrListView === "map")}
		>
			<div
				className={`adventure-tab-inner page-max-width ${
					!(isPortraitLayout && isMapShowing) ? "extend-behind-masthead" : ""
				}`}
			>
				{isMapShowing && (
					<ExperienceMap
						experiences={allExperiences}
						experiencesInBounds={allExperiencesInSelectedDay}
						selectedExperienceId={selectedExperience ? selectedExperience.uniqueId : ""}
						getPinIconUrl={exp =>
							`/assets/map-pins/pin-day-${
								(exp as UniqueScheduledExperience).tripDay.dayNumber! -
								7 * (Math.ceil((exp as UniqueScheduledExperience).tripDay.dayNumber! / 7) - 1)
							}-n-${(exp as UniqueScheduledExperience).experienceIndexWithinTrip + 1}.png`
						}
						onMarkerClick={experience => {
							setSelectedExperience(experience)
							setExperienceSelectedFromMap(experience)
						}}
					/>
				)}
				{props.currentSchedule?.status !== "error" ? (
					isMapShowing && isPortraitLayout ? (
						<div role="feed" id="experience-carousel">
							<div ref={swiperRef} className="swiper">
								<div className="swiper-wrapper">
									{carouselSlides.map((experienceOrTripDay, idx) => (
										<div
											role="article"
											key={`${(experienceOrTripDay as UniqueScheduledExperience).id ?? "day"}_${
												(experienceOrTripDay as UniqueScheduledExperience).tripDay?.dayNumber ??
												(experienceOrTripDay as TripDay).dayNumber
											}_${idx}`}
											className="swiper-slide"
											style={{
												["--cscd-timeline-color" as string]: `rgb(var(--cscd-day-${
													(experienceOrTripDay as UniqueScheduledExperience).tripDay
														?.dayNumber ??
													(experienceOrTripDay as TripDay).dayNumber -
														7 *
															(Math.ceil(
																(experienceOrTripDay as UniqueScheduledExperience)
																	.tripDay?.dayNumber ??
																	(experienceOrTripDay as TripDay).dayNumber / 7,
															) -
																1)
												}-color-rgb))`,
											}}
										>
											{isTripDay(experienceOrTripDay) && idx === 0 && (
												<IonCard className="title-card">
													<IonCardHeader
														style={{
															backgroundImage: `url(${
																props.currentSchedule.coverImage?.src ?? ""
															})`,
															backgroundSize: "cover",
														}}
													>
														<div className="title-card-text">
															<IonCardTitle>
																<h1>{props.currentSchedule.name}</h1>
															</IonCardTitle>
															<IonCardSubtitle className="small-title">
																{props.currentSchedule.days[0].date}
																&nbsp;&ndash;&nbsp;
																{
																	props.currentSchedule.days[
																		props.currentSchedule.days.length - 1
																	].date
																}
															</IonCardSubtitle>
														</div>
													</IonCardHeader>
												</IonCard>
											)}
											{isTripDay(experienceOrTripDay) && (
												<IonCard className="info-card adventure-day-title-card">
													<IonCardHeader>
														<IonCardTitle>
															<h2 className="small-title">
																<strong
																	style={{
																		color: `rgb(var(--cscd-day-${
																			(experienceOrTripDay as TripDay).dayNumber -
																			7 *
																				(Math.ceil(
																					(experienceOrTripDay as TripDay)
																						.dayNumber / 7,
																				) -
																					1)
																		}-color-rgb))`,
																	}}
																>
																	Day {(experienceOrTripDay as TripDay).dayNumber}
																</strong>
																&nbsp;
																{(experienceOrTripDay as TripDay).date}
															</h2>
														</IonCardTitle>
														<IonCardSubtitle className="small-subtitle">
															{(experienceOrTripDay as TripDay).lodging && " at "}
															{(experienceOrTripDay as TripDay).lodging && (
																<IonRouterLink
																	routerLink={`/${getExperienceDetailPath(
																		props.trip.id,
																		(experienceOrTripDay as TripDay).lodging!.id,
																	)}`}
																>
																	{(experienceOrTripDay as TripDay).lodging!.name} (
																	{(experienceOrTripDay as TripDay).lodgingCity})
																</IonRouterLink>
															)}
														</IonCardSubtitle>
													</IonCardHeader>
													<IonCardContent>
														<p>{(experienceOrTripDay as TripDay).summary}</p>
													</IonCardContent>
												</IonCard>
											)}
											{!isTripDay(experienceOrTripDay) && (
												<div className="carousel-experience">
													<ExperienceCard
														context="adventure"
														tripId={props.trip.id}
														experience={experienceOrTripDay as UniqueScheduledExperience}
														coverImageXYRatio={2}
														colorCodeBorder={
															experienceOrTripDay
																? `rgba(var(--cascade-day-${
																		(
																			experienceOrTripDay as UniqueScheduledExperience
																		).tripDay.dayNumber -
																		7 *
																			(Math.ceil(
																				(
																					experienceOrTripDay as UniqueScheduledExperience
																				).tripDay.dayNumber / 7,
																			) -
																				1)
																  }-color-rgb), 0.7)`
																: undefined
														}
														onFavorite={experienceId =>
															updateExperiencePref(experienceId, "favorite")
														}
														onUnfavorite={experienceId =>
															updateExperiencePref(experienceId, "neutral")
														}
														onHide={experienceId =>
															updateExperiencePref(experienceId, "hide")
														}
														onUnhide={experienceId =>
															updateExperiencePref(experienceId, "neutral")
														}
														cachedExpCardCoverPhotoHeight={
															props.cachedExpCardCoverPhotoHeight
														}
														setCachedExpCardCoverPhotoHeight={
															props.setCachedExpCardCoverPhotoHeight
														}
													>
														<div className="timeline-banner">
															<div className="card-number small-title">
																{allExperiences.findIndex(
																	ute =>
																		ute.uniqueId ===
																		(
																			experienceOrTripDay as UniqueScheduledExperience
																		).uniqueId,
																) + 1}
															</div>
															<div className="normal-text">
																<b>
																	{
																		(
																			experienceOrTripDay as UniqueScheduledExperience
																		).startTime
																	}
																</b>
															</div>
															<IonButton
																className="small-label directions-link"
																fill="clear"
																href={getUrlToGoogleMapsDirections(
																	(experienceOrTripDay as UniqueScheduledExperience)
																		.tripDay,
																	experienceOrTripDay as UniqueScheduledExperience,
																)}
																target="_blank"
															>
																<div slot="start">
																	<FontAwesomeIcon
																		icon={faDiamondTurnRight}
																		fontSize={"18px"}
																	/>
																</div>
																&nbsp;
																<span>Directions</span>
															</IonButton>
														</div>
													</ExperienceCard>
												</div>
											)}
										</div>
									))}
								</div>
							</div>
						</div>
					) : (
						<div
							role="feed"
							id="adventure-schedule-list"
							className="single-column"
							ref={contentRefLandscape}
						>
							<IonGrid className="adventure-grid">
								<IonRow>
									<IonCol
										size="12"
										className={
											(windowSize?.width ?? 0) <= MOBILE_LAYOUT_MAX_WIDTH_PX
												? "full-width-col"
												: ""
										}
									>
										<IonCard role="article" className="title-card">
											<IonCardHeader
												style={{
													backgroundImage: `url(${
														props.currentSchedule.coverImage?.src ?? ""
													})`,
													backgroundSize: "cover",
												}}
											>
												<div className="title-card-text">
													<IonCardTitle>
														<h1>{props.trip.name}</h1>
													</IonCardTitle>
													<div className="title-card-subtitle-row">
														<IonCardSubtitle className="small-title">
															{props.currentSchedule.days[0].date}
															&nbsp;&ndash;&nbsp;
															{
																props.currentSchedule.days[
																	props.currentSchedule.days.length - 1
																].date
															}
														</IonCardSubtitle>
														<div className="title-card-actions">
															{!isPortraitLayout && canEdit && (
																<>
																	<IonButton
																		id="open-trip-feedback"
																		fill="clear"
																		className="icon-button horizontal"
																		onClick={() => {
																			props.toggleTripFeedbackForm(
																				!!props.currentSchedule.userRating,
																			)
																		}}
																	>
																		<FontAwesomeIcon
																			icon={faWandMagicSparkles}
																			style={{ fontSize: "15px" }}
																		/>
																		<span className="small-label">
																			Modify adventure
																		</span>
																	</IonButton>
																</>
															)}
														</div>
													</div>
												</div>
											</IonCardHeader>
										</IonCard>
									</IonCol>
								</IonRow>
								{props.currentSchedule.days.map((tripDay, dayIdx) => (
									<IonRow
										ref={el => (dayRowsRef.current[dayIdx] = el)}
										key={`day_${tripDay.dayNumber}`}
										className="day-row"
										style={{
											"--cscd-timeline-color": `rgb(var(--cscd-day-${
												tripDay.dayNumber - 7 * (Math.ceil(tripDay.dayNumber / 7) - 1)
											}-color-rgb))`,
										}}
									>
										<IonCol size="12">
											<IonCard role="article" className="info-card adventure-day-title-card">
												<IonCardHeader>
													<IonCardTitle>
														<h2 className="small-title">
															<strong
																style={{
																	color: `rgb(var(--cscd-day-${
																		tripDay.dayNumber -
																		7 * (Math.ceil(tripDay.dayNumber / 7) - 1)
																	}-color-rgb))`,
																}}
															>
																Day {tripDay.dayNumber}
															</strong>
															&nbsp;
															{tripDay.date}
														</h2>
													</IonCardTitle>
													<IonCardSubtitle className="small-subtitle">
														{tripDay.lodging && " at "}
														{tripDay.lodging && (
															<IonRouterLink
																routerLink={`/${getExperienceDetailPath(
																	props.trip.id,
																	tripDay.lodging.id,
																)}`}
															>
																{tripDay.lodging.name} ({tripDay.lodgingCity})
															</IonRouterLink>
														)}
													</IonCardSubtitle>
												</IonCardHeader>
												<IonCardContent>
													<p>{tripDay.summary}</p>
												</IonCardContent>
											</IonCard>
										</IonCol>
										{!tripDay.events.length && (
											<IonCol size="12" key={`day_${tripDay.dayNumber}_skeleton`}>
												<IonCard className="content-card content-loading">
													<IonCardHeader>
														<IonThumbnail>
															<IonSkeletonText animated />
														</IonThumbnail>
														<h2>
															<IonSkeletonText animated />
														</h2>
														<p>
															<IonSkeletonText animated />
														</p>
														<p>
															<IonSkeletonText animated />
														</p>
													</IonCardHeader>
												</IonCard>
											</IonCol>
										)}
										{tripDay.events.map((experience, idx) => (
											<IonCol
												ref={el => {
													experienceColumnsRef.current[
														allExperiences.findIndex(
															ute =>
																ute.tripDay.dayNumber === dayIdx + 1 &&
																ute.experienceIndexWithinTripDay === idx,
														)
													] = el
												}}
												size="12"
												key={`${experience.id}_${tripDay.dayNumber}_${idx}`}
											>
												<div className="timeline-banner">
													<div className="card-number small-title">
														{allExperiences.findIndex(
															ute =>
																ute.tripDay.dayNumber === dayIdx + 1 &&
																ute.experienceIndexWithinTripDay === idx,
														) + 1}
													</div>
													<div className="normal-text">
														<FontAwesomeIcon icon={faClock} />
														&nbsp;
														<b>{experience.startTime}</b>
													</div>
													<IonButton
														className="small-label directions-link"
														fill="clear"
														href={getUrlToGoogleMapsDirections(tripDay, experience)}
														target="_blank"
													>
														<div slot="start">
															<FontAwesomeIcon
																icon={faDiamondTurnRight}
																fontSize={"18px"}
															/>
														</div>
														&nbsp;
														<span>Directions</span>
													</IonButton>
												</div>
												<ExperienceCard
													context="adventure"
													tripId={props.trip.id}
													experience={experience as Experience | ScheduledExperience}
													coverImageXYRatio={1.5}
													colorCodeBorder={
														tripDay
															? `rgba(var(--cascade-day-${
																	tripDay.dayNumber -
																	7 * (Math.ceil(tripDay.dayNumber / 7) - 1)
															  }-color-rgb), 0.7)`
															: undefined
													}
													onFavorite={experienceId =>
														updateExperiencePref(experienceId, "favorite")
													}
													onUnfavorite={experienceId =>
														updateExperiencePref(experienceId, "neutral")
													}
													onHide={experienceId => updateExperiencePref(experienceId, "hide")}
													onUnhide={experienceId =>
														updateExperiencePref(experienceId, "neutral")
													}
													cachedExpCardCoverPhotoHeight={props.cachedExpCardCoverPhotoHeight}
													setCachedExpCardCoverPhotoHeight={
														props.setCachedExpCardCoverPhotoHeight
													}
												/>
											</IonCol>
										))}
									</IonRow>
								))}
							</IonGrid>
						</div>
					)
				) : (
					<div className="page-max-width extend-behind-masthead">
						<IonCard className="info-card">
							<IonCardHeader>
								<h1>We're working on your adventure...</h1>
							</IonCardHeader>
						</IonCard>
					</div>
				)}
			</div>
		</IonContent>
	)

	function updateExperiencePref(experienceId: string, prefType: Experience["myPreference"]): void {
		if (selectedExperience?.id === experienceId) {
			setSelectedExperience({
				...selectedExperience,
				myPreference: prefType,
			})
		}
		props.refreshCurrentSchedule({
			...props.currentSchedule,
			days: props.currentSchedule.days.map(day => ({
				...day,
				events: day.events.map(ev => {
					if (ev.id === experienceId) {
						return { ...ev, myPreference: prefType }
					}
					return ev
				}),
			})),
		})
		// props.refreshDiscover(
		// 	props.discover.map(experience => {
		// 		const exp = experience as Experience
		// 		if (exp.id === experienceId) {
		// 			return {
		// 				...exp,
		// 				myPreference: prefType,
		// 			}
		// 		}
		// 		return exp
		// 	}),
		// )
		props.refreshFavorites(
			props.favorites.map(experience => {
				const exp = experience as Experience
				if (exp.id === experienceId) {
					return {
						...exp,
						myPreference: prefType,
					}
				}
				return exp
			}),
		)
		commitExperiencePref(props, experienceId, prefType)
	}
}

export default AdventureScheduleTab

function getUrlToGoogleMapsDirections(day: TripDay, experience: ScheduledExperience): string {
	const experienceIndexWithinDay = day.events.findIndex(evt => evt.id === experience.id)
	let maybeOriginParam: string | undefined =
		"origin=" +
		encodeURIComponent(
			experienceIndexWithinDay === 0 && day.lodging?.id !== experience.id
				? day.lodging?.address ?? ""
				: day.events[experienceIndexWithinDay - 1]?.address ?? "",
		)
	if (maybeOriginParam === "origin=") {
		maybeOriginParam = undefined
	}
	let maybeDestParam: string | undefined = "destination=" + encodeURIComponent(experience.address ?? "")
	if (maybeDestParam === "destination=") {
		maybeDestParam = undefined
	}
	const params = [maybeOriginParam, maybeDestParam].filter(param => param !== undefined)
	return `https://www.google.com/maps/dir/?api=1&${params.join("&")}`
}

function isTripDay(experienceOrTripDay: UniqueScheduledExperience | TripDay): boolean {
	return (experienceOrTripDay as UniqueScheduledExperience).uniqueId == undefined
}

const commitExperiencePref = debounce(
	(props: TripPageProps, experienceId: string, prefType: Experience["myPreference"]) => {
		putExperiencePref(props.trip.id, experienceId, prefType)
			.then(() => {
				props.refreshCurrentSchedule()
				props.refreshFavorites()
			})
			.catch(logError)
	},
	2000,
)

const LANDSCAPE_SAFE_AREA_TOP = 100
