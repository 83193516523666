import { IonRouterOutlet, setupIonicReact, useIonModal } from "@ionic/react"
import { IonReactRouter } from "@ionic/react-router"
import { initializeApp } from "firebase/app"
import { Component, FC, ReactNode, useState } from "react"
import { Redirect, Route } from "react-router-dom"
import { Subject, firstValueFrom } from "rxjs"
import SignInWithGoogleModal from "./auth/SignInWithGoogle"
import { User } from "./auth/model"
import firebaseConfig from "./config/firebase-config.json"
import AdventurePage from "./pages/adventure/AdventurePage"
import HomePage from "./pages/home/HomePage"
import PayWallPage from "./pages/pay-wall/PayWallPage"
import { ADVENTURE_PAGE, PAY_WALL_PAGE } from "./pages/routing/routes"

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css"
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css"
import "@ionic/react/css/structure.css"
import "@ionic/react/css/typography.css"
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css"
import "@ionic/react/css/flex-utils.css"
import "@ionic/react/css/float-elements.css"
import "@ionic/react/css/padding.css"
import "@ionic/react/css/text-alignment.css"
import "@ionic/react/css/text-transformation.css"
/* Theme */
import { PageProps } from "./pages/model"
import "./theme/main.css"
import "./theme/variables.css"
import SavedAdventuresPage from "./pages/adventures/SavedAdventuresPage"
import logError from "./error-handling/log-error"
import AboutPage from "./pages/about/AboutPage"
import CommunityAdventuresPage from "./pages/adventures/CommunityAdventuresPage"

setupIonicReact({ swipeBackEnabled: false })
initializeApp(firebaseConfig)

interface AppRouterProps extends PageProps {
	setUser: (user?: User) => void
}

const AppRouter: FC<AppRouterProps> = ({ user, userLoading, setUser }) => {
	const [signInSuccess$] = useState(new Subject<User>())
	const [presentSignIn, dismissSignIn] = useIonModal(SignInWithGoogleModal, {
		user,
		completeSignIn,
	})

	/** The callback passed to "sign in with Google", called on successful sign in */
	function completeSignIn(_user: User): void {
		signInSuccess$.next(_user)
	}

	async function signIn(): Promise<void> {
		presentSignIn({
			canDismiss: true,
			backdropDismiss: false,
			initialBreakpoint: 0.3,
			breakpoints: [0.3],
		})
		const _user = await firstValueFrom(signInSuccess$)
		setUser(_user)
		dismissSignIn()
	}

	return (
		<ErrorBoundary user={user} userLoading={userLoading} setUser={setUser} signIn={signIn}>
			<IonReactRouter>
				<IonRouterOutlet id="main" animated={false}>
					<Route exact={true} path="/">
						<HomePage user={user} userLoading={userLoading} signIn={signIn} />
					</Route>
					<Route exact={true} path="/about">
						<AboutPage user={user} userLoading={userLoading} />
					</Route>
					<Route exact={true} path={`/${PAY_WALL_PAGE}`}>
						<PayWallPage user={user} userLoading={userLoading} />
					</Route>
					<Route exact={true} path="/adventures">
						<SavedAdventuresPage user={user} userLoading={userLoading} />
					</Route>
					<Route exact={true} path="/community">
						<CommunityAdventuresPage user={user} userLoading={userLoading} />
					</Route>
					<Route path={`/${ADVENTURE_PAGE}`}>
						<AdventurePage user={user} userLoading={userLoading} signIn={signIn} />
					</Route>
					<Redirect to="/" />
				</IonRouterOutlet>
			</IonReactRouter>
		</ErrorBoundary>
	)
}

export default AppRouter

class ErrorBoundary extends Component<
	AppRouterProps & { signIn: () => Promise<void>; children: ReactNode },
	{ hasError: boolean }
> {
	public componentDidCatch(err: Error) {
		logError(err)
		this.setState({ hasError: true })
	}

	public static getDerivedStateFromError(err: Error) {
		return { hasError: true }
	}

	public render() {
		return this.state?.hasError ? (
			<HomePage user={this.props.user} userLoading={this.props.userLoading} signIn={this.props.signIn} />
		) : (
			this.props.children
		)
	}
}
