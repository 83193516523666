import { IonApp, IonPage, setupIonicReact } from "@ionic/react"
import { initializeApp } from "firebase/app"
import { Component, FC, ReactNode, useEffect } from "react"
import ReactGA from "react-ga4"
import { useUser } from "./auth/hooks/use-user"
import Menu from "./components/menu/Menu"
import NotFoundPage from "./components/not-found/NotFound"
import firebaseConfig from "./config/firebase-config.json"
import logError from "./error-handling/log-error"

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css"
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css"
import "@ionic/react/css/structure.css"
import "@ionic/react/css/typography.css"
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css"
import "@ionic/react/css/flex-utils.css"
import "@ionic/react/css/float-elements.css"
import "@ionic/react/css/padding.css"
import "@ionic/react/css/text-alignment.css"
import "@ionic/react/css/text-transformation.css"
/* Theme */
import AppRouter from "./AppRouter"
import "./theme/main.css"
import "./theme/variables.css"

setupIonicReact({ swipeBackEnabled: false })
initializeApp(firebaseConfig)

const App: FC = () => {
	const [user, setUser, userLoading] = useUser()

	// Initialize Google Analytics
	useEffect(() => {
		ReactGA.initialize("G-W5E8GG652N")
	}, [])

	return (
		<IonApp>
			<Menu user={user} setUser={setUser} />
			<AppRouter user={user} setUser={setUser} userLoading={userLoading} />
		</IonApp>
	)
}

export default App
