import { IonBackButton, IonButton, IonButtons, IonHeader, IonMenuToggle, IonToolbar } from "@ionic/react"
import { chevronBackOutline } from "ionicons/icons"
import { ReactNode, useLayoutEffect, useState } from "react"
import { MOBILE_LAYOUT_MAX_WIDTH_PX } from "../../layout/config"
import useWindowSize from "../../layout/hooks/use-window-size"
import { PageProps } from "../../pages/model"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faNavicon } from "@fortawesome/free-solid-svg-icons"

export interface MastheadProps extends PageProps {
	largeLogoAlways?: boolean
	children?: ReactNode
	background: "transparent" | "frosted"
	disableMenuToggle?: boolean
	hideLogo?: boolean
	showBackButton?: boolean
	getBackButtonDefaultHref?: () => string
}

const Masthead: React.FC<MastheadProps> = ({
	children,
	largeLogoAlways,
	background,
	disableMenuToggle,
	hideLogo,
	showBackButton,
	getBackButtonDefaultHref,
}) => {
	const windowSize = useWindowSize()
	const [logoLayoutClass, setLogoLayoutClass] = useState<"small" | "large">("small")

	useLayoutEffect(() => {
		if (largeLogoAlways) {
			setLogoLayoutClass("large")
		} else if ((windowSize.width ?? 0) <= MOBILE_LAYOUT_MAX_WIDTH_PX && logoLayoutClass !== "small") {
			setLogoLayoutClass("small")
		} else if ((windowSize.width ?? 0) > MOBILE_LAYOUT_MAX_WIDTH_PX && logoLayoutClass !== "large") {
			setLogoLayoutClass("large")
		}
	}, [windowSize])

	return (
		<IonHeader className={`bg-${background}`} translucent={background === "frosted"} style={{ zIndex: "110" }}>
			<IonToolbar>
				<IonButtons slot="start">
					{showBackButton && (
						<IonBackButton
							defaultHref={getBackButtonDefaultHref?.() ?? "/"}
							style={{
								"minWidth": "24px",
								"--background-hover": "transparent",
								"--ripple-color": "transparent",
								"--padding-start": "0",
								"--padding-end": "0",
							}}
							icon={chevronBackOutline}
							mode="md"
						/>
					)}
					{!hideLogo &&
						(disableMenuToggle ? (
							<IonButton disabled style={{ opacity: "1" }}>
								<div className={`logo ` + logoLayoutClass}></div>
							</IonButton>
						) : (
							<IonMenuToggle>
								<IonButton>
									<FontAwesomeIcon
										id="main-menu-icon"
										icon={faNavicon}
										size="xl"
										style={{ marginRight: "12px" }}
									/>
									<div className={`logo ` + logoLayoutClass}></div>
								</IonButton>
							</IonMenuToggle>
						))}
				</IonButtons>
				{children}
			</IonToolbar>
		</IonHeader>
	)
}

export default Masthead
