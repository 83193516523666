import { put } from "../../../../../api-utils"
import { experiencePreferenceToApi } from "../../../../mapping"
import { Experience } from "../../../../ui-model"

export default async function (
	tripId: string,
	experienceId: string,
	prefType: Experience["myPreference"],
): Promise<void> {
	await put(`v0/trips/${tripId}/experience/${experienceId}?preference=${experiencePreferenceToApi(prefType)}`)
}
