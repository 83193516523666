import { get } from "../../../../../api-utils"
import { ApiItinerary } from "../../../../api-model"
import { apiItineraryToTripSchedule } from "../../../../mapping"
import { TripSchedule } from "../../../../ui-model"

export default async function (tripId: string): Promise<TripSchedule | undefined> {
	const tripVersion = await get<ApiItinerary>(`v0/trips/${tripId}/versions/latest`)
	// TODO handle payment required response
	/* message format
	{
		"message": "Payment required to create trip bb6db3b5",
		"access_code": "bb6db3b5"
	}
	 */
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const err = tripVersion as ErrorResponse
	if (err?.message) throw new Error(err?.message)
	if (!tripVersion) return undefined
	return apiItineraryToTripSchedule(tripVersion)
}
