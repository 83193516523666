import { TripSchedule } from "../../api/v0/ui-model"

const AdventureBackgroundImage: React.FC<TripSchedule> = ({ coverImage }) => (
	<div
		className="adventure-bg-image"
		style={{
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			backgroundImage: coverImage?.src ? `url(${coverImage?.src})` : "url(/assets/adventure-default-bg.jpg)",
			filter: "blur(6px)",
			transform: "scale(1.1, 1.1)",
			backgroundSize: "cover",
			backgroundPosition: "50% 50%",
		}}
		aria-label={coverImage?.altText ?? "background image of beautiful scenery"}
	></div>
)

export default AdventureBackgroundImage
