import { isEqual } from "lodash"
import { FC, useEffect, useMemo, useRef, useState } from "react"
import { UniqueExperience, UniqueScheduledExperience } from "../../api/v0/ui-model"

interface ExperienceMapProps<ExperienceType> {
	selectedExperienceId?: string
	experiences: ExperienceType[]
	experiencesInBounds: ExperienceType[]
	onMarkerClick: (experience: ExperienceType) => void
	getPinIconUrl?: (experience: ExperienceType) => string
}

const ExperienceMap: FC<ExperienceMapProps<UniqueExperience | UniqueScheduledExperience>> = ({
	selectedExperienceId,
	experiences,
	experiencesInBounds,
	onMarkerClick,
	getPinIconUrl,
}) => {
	const [googleMaps, setGoogleMaps] = useState<typeof google.maps>(google?.maps)
	const [googleMap, setGoogleMap] = useState<google.maps.Map>()
	const [allMarkers, setAllMarkers] = useState<google.maps.marker.AdvancedMarkerElement[]>()
	const mapRef = useRef<HTMLDivElement>(null)

	// Load Google Maps
	useEffect(() => {
		if (!google?.maps && mapRef && mapRef.current) {
			const script = document.createElement("script")
			script.async = true
			script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=maps&v=beta`
			script.onload = () => {
				setTimeout(() => {
					if (google?.maps != undefined) {
						console.log("google.maps was loaded")
						setGoogleMaps(google.maps)
					}
				})
			}
			document.body.appendChild(script)
		}
	}, [mapRef])

	// Once Google Maps has loaded, create the Map object
	useEffect(() => {
		if (googleMaps) {
			googleMaps.importLibrary("maps").then(lib => {
				const { Map } = lib as google.maps.MapsLibrary
				const map = new Map(mapRef.current!, {
					mapId: "5a9ea4f493b856f0",
					disableDefaultUI: true,
				})
				setGoogleMap(map)
			})
		}
	}, [googleMaps])

	// Once the Map is created, set new markers whenever the selected experience changes,
	// so the map knows which one is selected
	useEffect(() => {
		if (googleMap && experiences) {
			googleMaps.importLibrary("marker").then(lib => {
				const { AdvancedMarkerElement } = lib as google.maps.MarkerLibrary
				const markers = experiences
					.filter(exp => exp.geolocation?.location)
					.map(exp => {
						const iconSize =
							exp.uniqueId === selectedExperienceId
								? new googleMaps.Size(39.75, 60)
								: new googleMaps.Size(26.5, 40)
						const zIndex = exp.uniqueId === selectedExperienceId ? 2 : 1

						const markerImg = document.createElement("img")
						markerImg.width = iconSize.width
						markerImg.height = iconSize.height
						markerImg.src =
							getPinIconUrl?.(exp) ??
							"https://creazilla-store.fra1.digitaloceanspaces.com/icons/3433523/marker-icon-md.png"

						const markerElement = new AdvancedMarkerElement({
							map: googleMap,
							position: exp.geolocation!.location,
							content: markerImg,
							title: exp.uniqueId,
							zIndex,
						})

						markerElement.addListener("click", () => onMarkerClick(exp))

						return markerElement
					}, {})

				// Hack to make sure that no 2 pins exactly overlap
				// (If a trip has the same experience on 2 different days, the map picks 1 to use for both,
				// probably because the map identifies the pin using its coordinates)
				markers.forEach(marker => {
					const markerWithSameCoord = markers.find(
						m => marker.id !== m.id && isEqual(m.position, marker.position),
					)
					if (markerWithSameCoord) {
						const pos = markerWithSameCoord.position as google.maps.LatLng
						const posJson = pos.toJSON()

						if (posJson?.lat && posJson?.lng) {
							console.log("found marker w same coord", posJson!.lat)
							// Shift it about a meter SE
							markerWithSameCoord.position = {
								lat: posJson.lat + 0.000001,
								lng: posJson.lng + 0.000001,
							}
							console.log("now coord is", posJson)
						}
					}
				})

				setAllMarkers(oldMarkers => {
					oldMarkers?.forEach(m => (m.map = null))
					return markers
				})
			})
		}
	}, [googleMap, experiences, selectedExperienceId])

	// When experiencesInBounds changes, change the bounds
	const bounds = useMemo(() => {
		if (googleMaps && allMarkers?.length) {
			return allMarkers.reduce((bnds, marker) => {
				const markerPos = marker.position as google.maps.LatLng | undefined
				const markerPosJson = markerPos?.toJSON?.()
				const isInBounds = experiencesInBounds.find(expInBnds => {
					if (markerPosJson) {
						const { lat, lng } = markerPosJson
						return isEqual(expInBnds?.geolocation?.location, { lat, lng })
					}
					return false
				})
				if (markerPosJson && isInBounds) {
					return bnds.extend(markerPosJson)
				}
				return bnds
			}, new googleMaps.LatLngBounds())
		}
	}, [allMarkers, experiencesInBounds])

	// Whenever the bounds change, zoom to those bounds
	useEffect(() => {
		if (bounds) {
			googleMap?.fitBounds?.(bounds)
		}
	}, [bounds])

	// Zoom in whenever an experience is selected
	// TODO: Is this a weird UX?
	// useEffect(() => {
	// 	if (bounds && selectedExperienceId) {
	// 		const selectedExp = experiences.find((exp) => exp.uniqueId === selectedExperienceId)
	// 		if (selectedExp && selectedExp.geolocation) {
	// 			googleMap?.panTo?.(selectedExp.geolocation.location)
	// 		}
	// 	}
	// }, [bounds, selectedExperienceId])

	return <div id="map" ref={mapRef}></div>
}

export default ExperienceMap
