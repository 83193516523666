import { faCalendarDay, faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DatetimeChangeEventDetail, IonDatetimeCustomEvent } from "@ionic/core"
import {
	IonCol,
	IonContent,
	IonDatetime,
	IonDatetimeButton,
	IonGrid,
	IonLabel,
	IonModal,
	IonPage,
	IonRow,
	IonSpinner,
	useIonRouter,
	useIonToast,
} from "@ionic/react"
import { addDays, isWeekend, subDays } from "date-fns"
import { FC, FormEvent, useRef, useState } from "react"
import postTrip from "../../api/v0/trips/post"
import { TripInput } from "../../api/v0/ui-model"
import Masthead from "../../components/header/Masthead"
import logError from "../../error-handling/log-error"
import { PageProps } from "../model"
import { getAdventurePagePath, getPayWallPagePath } from "../routing/routes"

interface HomePageProps extends PageProps {
	signIn: () => Promise<void>
}

const HomePage: FC<HomePageProps> = ({ user, userLoading, signIn }) => {
	const defaultDateInterval = getDefaultDateInterval()
	const startDateModalRef = useRef<HTMLIonModalElement>(null)
	const endDateModalRef = useRef<HTMLIonModalElement>(null)
	const router = useIonRouter()
	// TODO: Use times, not dates
	const [startDate, setStartDate] = useState<string>(defaultDateInterval[0])
	const [endDate, setEndDate] = useState<string>(defaultDateInterval[1])
	const [formIsValid, setFormIsValid] = useState(false)
	const [formIsSubmitted, setFormIsSubmitted] = useState(false)
	const [showToast] = useIonToast()

	/**
	 * The default date interval is the upcoming weekend, departing Friday and arriving back Sunday
	 * @returns a tuple of ISO date strings
	 */
	function getDefaultDateInterval(): [string, string] {
		let startOfThisWeekend: Date | undefined = undefined
		const today = new Date()

		// Find the upcoming weekend
		for (let n = 1; n <= 7; n++) {
			const candidateDate = addDays(today, n)
			if (isWeekend(candidateDate)) {
				startOfThisWeekend = candidateDate
				return [
					subDays(startOfThisWeekend, 1).toISOString().split("T")[0],
					addDays(startOfThisWeekend, 1).toISOString().split("T")[0],
				]
			}
		}

		return [subDays(today, 1).toISOString().split("T")[0], addDays(today, 1).toISOString().split("T")[0]]
	}

	function ionDatetimeProps(which: "start" | "end"): Parameters<typeof IonDatetime>[0] {
		return {
			value: which === "start" ? startDate : endDate,
			id: `${which}-date`,
			name: `${which}-date`,
			presentation: "date",
			placeholder: `${which} date`,
			color: "dark",
			// Only future allowed
			isDateEnabled: (dateIsoStr: string) =>
				new Date(dateIsoStr.split("T")[0]) >= new Date(new Date().toISOString().split("T")[0]),
			onIonChange: (e: IonDatetimeCustomEvent<DatetimeChangeEventDetail>) => {
				if (which === "start") {
					const newStartDate = (e.detail.value as string).split("T")[0] as string
					setStartDate(newStartDate)
					if (endDate && new Date(endDate) && new Date(endDate) < new Date(newStartDate)) {
						setEndDate(newStartDate)
					}
					startDateModalRef.current?.dismiss()
				} else {
					const newEndDate = (e.detail.value as string).split("T")[0] as string
					setEndDate(newEndDate)
					if (startDate && new Date(startDate) && new Date(startDate) > new Date(newEndDate)) {
						setStartDate(newEndDate)
					}
					endDateModalRef.current?.dismiss()
				}
			},
		}
	}

	function calculateDurationInDays(startDateString: string, endDateString: string): number {
		const _startDate = new Date(startDateString)
		const _endDate = new Date(endDateString)

		const diffInMilliseconds = Math.abs(_endDate.getTime() - _startDate.getTime())
		const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24))

		return diffInDays + 1 // We need to add one as a same day trip is considered 1 day
	}

	async function onSubmitAdventureForm(e: FormEvent<HTMLFormElement>): Promise<void> {
		try {
			e.preventDefault()
			const formElement = e.target as HTMLFormElement
			setFormIsSubmitted(true)
			if (user == undefined) {
				await signIn()
			}
			const tripStub = await postTrip(getTripInput(new FormData(formElement)))
			// FIXME: For some reason we get a 404 from /trip/<code>/latest if we don't wait for a moment here
			setTimeout(() => router.push(`/${getAdventurePagePath(tripStub.id)}`), 500)
		} catch (err) {
			const paymentRequiredErr = err as { message: string; access_code: string }
			if (paymentRequiredErr?.message?.match(/payment required/gi)) {
				router.push(`/${getPayWallPagePath(paymentRequiredErr.access_code)}`)
			} else {
				logError(err)
				showToast("Something went wrong -- sorry about that. Try submitting the form again.")
			}
		}
	}

	function getTripInput(formData: FormData): TripInput {
		const tripInput: TripInput = {
			...(Object.fromEntries(formData) as Partial<TripInput> as TripInput),
			// TODO: use user inputs here instead of hard coding times
			arrivalTime: "08:00",
			departureTime: "17:00",
			startDate: startDate!,
			durationDays: calculateDurationInDays(startDate!, endDate!),
		}
		// Supports quick use of the trip planning tool, ensuring that the
		// results from just putting a default search in are still valuable
		// TODO: Move this into default form behavior
		if (!tripInput.interests) {
			tripInput.interests = "points of interest"
		}
		return tripInput
	}

	return (
		<IonPage id="home-page" className="dark-bg">
			<div
				className="home-bg-image"
				style={{
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
					background:
						"url(https://images.pexels.com/photos/788200/pexels-photo-788200.jpeg) center top / cover",
					filter: "blur(5px)",
					transform: "scale(1.1, 1.1)",
					backgroundSize: "cover",
					backgroundPosition: "50% 30%",
				}}
				aria-label={"background image of beautiful scenery"}
			></div>
			<Masthead user={user} userLoading={userLoading} background="transparent" largeLogoAlways={true} />
			<IonContent fullscreen={true}>
				<form onSubmit={onSubmitAdventureForm}>
					<IonGrid className="page-max-width">
						<IonRow>
							<IonCol>
								<h1 className="larger-title">Plan your next trip in one click</h1>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="12">
								<IonLabel>Destination</IonLabel>
								<div className="cscd-input">
									<input
										id="destination"
										name="destination"
										placeholder="Where do you want to go?"
										onInput={() => updateFormValidity()}
										required
									/>
								</div>
							</IonCol>

							<IonCol size="12">
								<IonLabel>Interests / Activities</IonLabel>
								<div className="cscd-input">
									<input
										id="interests"
										name="interests"
										placeholder="breathtaking views, local cuisine"
										onInput={() => updateFormValidity()}
									/>
								</div>
							</IonCol>

							<IonCol size="6">
								<IonLabel id="departure-date-label">
									<FontAwesomeIcon icon={faCalendarDay} />
									&nbsp;&nbsp;Departure Date
								</IonLabel>
								<IonDatetimeButton aria-labelledby="departure-date-label" datetime="start-date" />
								<IonModal keepContentsMounted={true} ref={startDateModalRef}>
									<IonDatetime {...ionDatetimeProps("start")} />
								</IonModal>
							</IonCol>

							<IonCol size="6">
								<IonLabel id="return-date-label">
									<FontAwesomeIcon icon={faCalendarDay} />
									&nbsp;&nbsp;Return Date
								</IonLabel>
								<IonDatetimeButton aria-labelledby="return-date-label" datetime="end-date" />
								<IonModal keepContentsMounted={true} ref={endDateModalRef}>
									<IonDatetime {...ionDatetimeProps("end")} />
								</IonModal>
							</IonCol>

							<IonCol>
								<button
									className="bold-button white-button"
									id="submit"
									type="submit"
									disabled={!formIsValid || formIsSubmitted}
								>
									{formIsSubmitted ? (
										<IonSpinner slot="icon-only" name="dots" />
									) : (
										<>
											<div slot="start" style={{ marginRight: "10px" }}>
												<FontAwesomeIcon icon={faWandMagicSparkles} />
											</div>
											<div slot="start">Get started</div>
										</>
									)}
								</button>
							</IonCol>
						</IonRow>
					</IonGrid>
				</form>
			</IonContent>
		</IonPage>
	)

	function updateFormValidity(): void {
		const requiredFields = document.querySelectorAll(
			"#home-page form input[required]",
		) as NodeListOf<HTMLInputElement>

		Array.from(requiredFields).forEach(input => {
			if (!input.value?.length) {
				setFormIsValid(false)
			} else {
				setFormIsValid(true)
			}
		})
	}
}

export default HomePage
