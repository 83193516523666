import { useIonToast } from "@ionic/react"
import { useEffect, useState } from "react"
import apiGetTripVersions from "../../../api/v0/trips/_code_/versions/get"
import apiGetLatestTripVersion from "../../../api/v0/trips/_code_/versions/latest/get"
import { TripSchedule } from "../../../api/v0/ui-model"
import { User } from "../../../auth/model"

export default (
	tripId: string,
	user: User | undefined,
): [TripSchedule | undefined, (eagerUpdate?: TripSchedule) => void, NotFound, Loading, Regenerating, Error] => {
	const [tripSch, setTripSchedule] = useState<TripSchedule | undefined>()
	const [_doneTripSch, _setDoneTripSchedule] = useState<TripSchedule | undefined>()
	const [notFound, setNotFound] = useState<NotFound>(false)
	const [loading, setLoading] = useState<Loading>(true)
	const [regenerating, setRegenerating] = useState<Loading>(false)
	const [error, setError] = useState<Error>(undefined)
	const [showToast] = useIonToast()

	const load = (eagerUpdate?: TripSchedule) => {
		let stopListening = false

		if (!user) return

		if (eagerUpdate) {
			setTripSchedule(eagerUpdate)
		} else {
			;(async function () {
				try {
					for await (const [latestTripSch, latestDoneTripSch, latestGoodTripSch] of getLatestTripSchedule(
						tripId,
						user,
					)) {
						if (stopListening || !user) break

						if (latestTripSch) {
							if (
								latestTripSch.status === "generating" &&
								latestDoneTripSch?.status === "done" &&
								latestTripSch.id !== latestDoneTripSch?.id
							) {
								setRegenerating(true)
							} else {
								setRegenerating(false)
							}

							if (latestTripSch.status === "error") {
								showToast({
									message: "There was an error generating your adventure. Please try again.",
									buttons: [
										{
											text: "Dismiss",
											role: "cancel",
										},
									],
								})
							}

							setTripSchedule(latestDoneTripSch ?? latestGoodTripSch ?? latestTripSch)
						} else {
							setNotFound(true)
						}
					}
				} catch (e) {
					console.error("error caught in use-trip:", e)
					setError(e)
					// setNotFound(true)
				} finally {
					setLoading(false)
				}
			})()
		}

		// If you return a function to the useEffect callback, it will be used
		// by React as an "unsubscribe" function for this useEffect (called when
		// the component is unmounted)
		return function () {
			stopListening = true
		}
	}

	useEffect(load, [tripId, user])

	return [tripSch, load, notFound, loading, regenerating, error]
}

const getLatestTripSchedule = async function* (
	tripId: string,
	user: User | undefined,
): AsyncGenerator<[LatestTripSchedule, LatestDoneTripSchedule, LatestGoodTripSchedule]> {
	let allSchedules = await apiGetTripVersions(tripId)
	let tripSch: TripSchedule | undefined = allSchedules[0]
	console.log("start: yielding latest trip version", tripSch)
	yield [tripSch, latestDoneTripSchedule(), latestGoodTripSchedule()]
	while (tripSch?.status === "generating") {
		// Wait 2 seconds
		await new Promise(resolve => setTimeout(resolve, 2000))
		// Get the latest TripSchedule
		tripSch = await apiGetLatestTripVersion(tripId)
		// Refresh the list in memory
		if (tripSch) {
			const [_, ...allSchedulesButLatest] = allSchedules
			allSchedules = [tripSch, ...allSchedulesButLatest]
		}
		console.log("yielding latest trip version", tripSch)
		yield [tripSch, latestDoneTripSchedule(), latestGoodTripSchedule()]
	}

	function latestDoneTripSchedule(): TripSchedule | undefined {
		if (tripSch?.status === "done") {
			return tripSch
		}
		return allSchedules.find(sch => sch.status === "done")
	}

	function latestGoodTripSchedule(): TripSchedule | undefined {
		if (tripSch?.status !== "error") {
			return tripSch
		}
		return allSchedules.find(sch => sch.status !== "error")
	}
}

type Loading = boolean
type Regenerating = boolean
type Error = unknown
type NotFound = boolean
type LatestTripSchedule = TripSchedule | undefined
type LatestDoneTripSchedule = TripSchedule | undefined
type LatestGoodTripSchedule = TripSchedule | undefined
