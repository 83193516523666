import { get } from "../../../api-utils"
import { ApiTripPlan } from "../../api-model"
import { apiTripPlanToTrip } from "../../mapping"
import { Trip } from "../../ui-model"

export default async function (tripId: string, abortCtrl?: AbortController): Promise<Trip | undefined> {
	const tripPlan = await get<ApiTripPlan>(`v0/trips/${tripId}`, undefined, { signal: abortCtrl?.signal })
	if (!tripPlan) return undefined
	return apiTripPlanToTrip(tripPlan)
}
