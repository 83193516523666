import { post } from "../../../../../../api-utils"
import { ApiItineraryRating } from "../../../../../api-model"
import { TripRating } from "../../../../../ui-model"

export default async function (accessCode: string, rating: TripRating): Promise<void> {
	const reqBody: ApiItineraryRating = {
		rating: rating.ratingOneToFive,
		access_code: accessCode,
	}
	await post(`v0/rating`, reqBody)
}
